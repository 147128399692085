import { useMemo, useCallback } from 'react';

// material-ui
import { Avatar, Badge, Box, IconButton, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

// assets
import moment from 'moment';
import { INotification } from 'models/INotification';
import { useAppSelector } from '../../../../hooks/redux';
import useNotificationItem from '../../../../hooks/useNotificationItem';

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationItem = ({ notification }: { notification: INotification }) => {
    const { selectedLocation } = useAppSelector((state) => state.calendarFilter);
    const { isMessage, customerName, notificationData, handleClickNotification, handleDeleteNotification } =
        useNotificationItem(notification);

    const selectedTimeZone = selectedLocation?.time_zone;
    const notificationTimeZone = useMemo(() => selectedTimeZone ?? moment.tz.guess(), [selectedTimeZone]);
    const notificationTime = useMemo(() => (isMessage ? notification.created_at : notification?.data?.start_at), [isMessage, notification]);

    const handleSecondaryActionClick = useCallback(
        (event: React.MouseEvent) => {
            event.stopPropagation();
            handleDeleteNotification();
        },
        [handleDeleteNotification]
    );

    return (
        <ListItem
            disablePadding
            dense={true}
            divider
            alignItems="flex-start"
            secondaryAction={<ListItemSecondaryAction onClick={handleSecondaryActionClick} />}
            onClick={handleClickNotification}
        >
            <ListItemButton sx={{ backgroundColor: notification.read_at ? 'inherit' : 'grey.100' }}>
                <Box display="flex" alignItems="center" gap={1}>
                    <Badge
                        color="error"
                        variant="dot"
                        invisible={!!notification.read_at}
                        overlap="circular"
                        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    />
                    <ListItemAvatar>
                        <Avatar sx={{ backgroundColor: 'primary.light', color: 'primary.900' }}>{notificationData.icon}</Avatar>
                    </ListItemAvatar>
                </Box>
                <ListItemText
                    disableTypography
                    primary={
                        <Typography color="black" noWrap>
                            {notificationData.title}
                        </Typography>
                    }
                    secondary={
                        <ListItemSecondaryText
                            customerName={customerName}
                            isMessage={isMessage}
                            notificationTimeZone={notificationTimeZone}
                            notificationTime={notificationTime}
                        />
                    }
                />
            </ListItemButton>
        </ListItem>
    );
};

const ListItemSecondaryAction = ({ onClick }: { onClick: (event: React.MouseEvent) => void }) => {
    return (
        <IconButton edge="end" aria-label="delete" size="small" color="error" onClick={onClick}>
            <DeleteOutlineIcon />
        </IconButton>
    );
};

const ListItemSecondaryText = ({
    customerName,
    isMessage,
    notificationTimeZone,
    notificationTime
}: {
    customerName: string;
    isMessage: boolean;
    notificationTimeZone: string;
    notificationTime: string;
}) => {
    return (
        <>
            <Typography variant="subtitle2" color="textPrimary" noWrap>
                {!isMessage ? 'Customer' : 'From'}: {customerName}
            </Typography>
            <Typography variant="subtitle2">
                {!isMessage ? 'on ' : null}
                {notificationTimeZone
                    ? moment(notificationTime).tz(notificationTimeZone).format('M/D/YY [at] hh:mm A (z)')
                    : moment(notificationTime).format('M/D/YY [at] hh:mm A')}
            </Typography>
        </>
    );
};

export default NotificationItem;
