import { useCallback, useState } from 'react';
import { useAppSelector } from '../../hooks/redux';
import { Button, ButtonGroup, Box, CircularProgress, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import useCustomerAppointments from './hooks/useCustomerAppointments';
import AppointmentRow from './elements/AppointmentRow';

interface CustomerAppointmentsProps {
    customerId: number;
    onViewAppointment?: (id: number) => void;
}

export type CustomerAppointmentsType = 'upcoming' | 'past';

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
    '& .MuiButton-root': {
        borderRadius: 0,
        textTransform: 'capitalize',

        '&:first-child': {
            borderTopLeftRadius: '20px',
            borderBottomLeftRadius: '20px'
        },

        '&:last-child': {
            borderTopRightRadius: '20px',
            borderBottomRightRadius: '20px'
        },

        '&.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary': {
            backgroundColor: theme.palette.primary[900]
        },

        '&.MuiButton-outlined': {
            color: theme.palette.grey[800],
            // Because newColors key used
            // @ts-ignore
            borderColor: theme.palette.newColors?.darkBlue
        }
    }
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    textAlign: 'center',
    textTransform: 'capitalize',
    color: theme.palette.grey[600],
    fontSize: '1.2rem',
    margin: `${theme.spacing(5)} 0`
}));

const CustomerAppointments = ({ customerId, onViewAppointment }: CustomerAppointmentsProps) => {
    const { selectedEvent } = useAppSelector((state) => state.calendar);
    const [type, setType] = useState<CustomerAppointmentsType>('upcoming');
    const { appointments, getAppointments, cursor, isLoading } = useCustomerAppointments({ customerId, type });

    const onScroll = useCallback(
        (event: React.UIEvent<HTMLDivElement>) => {
            const element = event.target as HTMLDivElement;
            if (element.scrollHeight - element.scrollTop === element.clientHeight && cursor && !isLoading) {
                getAppointments();
            }
        },
        [cursor, getAppointments, isLoading]
    );

    return (
        <>
            <StyledButtonGroup variant="outlined" fullWidth>
                {(['upcoming', 'past'] as const).map((appType) => (
                    <Button
                        key={appType}
                        variant={type === appType ? 'contained' : 'outlined'}
                        color="primary"
                        onClick={() => setType(appType)}
                        disableElevation
                    >
                        {appType}
                    </Button>
                ))}
            </StyledButtonGroup>
            <Box onScroll={onScroll} sx={{ maxHeight: '400px', overflowY: 'auto' }} mt={2} position="relative">
                <Box sx={{ opacity: isLoading ? 0.5 : 1 }}>
                    {appointments.map((a) => (
                        <AppointmentRow
                            key={`ar-${a.id}`}
                            appointment={a}
                            onViewAppointment={onViewAppointment && selectedEvent?.id !== a.id ? () => onViewAppointment(a.id) : undefined}
                        />
                    ))}
                </Box>

                {!isLoading && !appointments.length && <StyledTypography>No {type} Appointments</StyledTypography>}
                {isLoading && (
                    <Box display="flex" justifyContent="center" sx={{ overflow: 'hidden' }}>
                        <CircularProgress />
                    </Box>
                )}
            </Box>
        </>
    );
};

export default CustomerAppointments;
