import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LayoutSliceProps {
    headerHeight: number;
    toolbarHeight: number;
}

const initialState: LayoutSliceProps = {
    headerHeight: 0,
    toolbarHeight: 0
};

export const layoutSlice = createSlice({
    name: 'calendar',
    initialState,
    reducers: {
        setHeaderHeight: (state, action: PayloadAction<number>) => {
            state.headerHeight = action.payload;
        },
        setToolbarHeight: (state, action: PayloadAction<number>) => {
            state.toolbarHeight = action.payload;
        }
    }
});

export const { setHeaderHeight, setToolbarHeight } = layoutSlice.actions;
