import { useMemo } from 'react';
import config, { Envs } from '../../config';

const useProfileCardUrl = () => {
    const webSiteSlugStartAdornment = useMemo(() => {
        switch (config.env) {
            case Envs.Develop:
            case Envs.Staging:
            case Envs.Production: {
                return 'https://';
            }
            default: {
                return `${window.location.origin}${config.apiUrl}/profile-card/`;
            }
        }
    }, []);

    const webSiteSlugEndAdornment = useMemo(() => {
        switch (config.env) {
            case Envs.Develop:
            case Envs.Staging: {
                return `.${config.env}.chilled.pro`;
            }
            case Envs.Production: {
                return '.chilled.pro';
            }
            default: {
                return '';
            }
        }
    }, []);

    return {
        webSiteSlugStartAdornment,
        webSiteSlugEndAdornment
    };
};

export default useProfileCardUrl;
