import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
    AutocompleteOption,
    AutocompleteResponse,
    IndustriesMapping,
    IndustryType,
    Place,
    PlaceAddressComponent,
    PlaceResponse
} from '../types';

// const placesApiEndpoint = 'http://localhost:8080/api/places';
const placesApiEndpoint = 'https://places.googleapis.com/v1/places';
const placesApiCommonBody = {
    key: process.env.REACT_APP_GOOGLE_PLACES_API_KEY ?? '',
    languageCode: 'en',
    regionCode: 'US'
};

const searchPlacesFields = [
    'suggestions.placePrediction.placeId',
    'suggestions.placePrediction.structuredFormat.mainText.text',
    'suggestions.placePrediction.structuredFormat.secondaryText.text'
];

const getPlaceFields = ['formattedAddress', 'addressComponents', 'types', 'internationalPhoneNumber', 'websiteUri', 'regularOpeningHours'];

const useGooglePlacesApi = () => {
    const sessionToken = uuidv4();

    const searchPlaces = useCallback(
        async (input: string): Promise<AutocompleteOption[]> => {
            const request = await fetch(`${placesApiEndpoint}:autocomplete`, {
                method: 'POST',
                body: new URLSearchParams({
                    ...placesApiCommonBody,
                    input,
                    fields: searchPlacesFields.join(','),
                    sessionToken,
                    includedPrimaryTypes: 'establishment',
                    includedRegionCodes: 'us'
                })
            });

            const response: AutocompleteResponse = await request.json();

            if (!request.ok) throw new Error(response.error);

            return (
                response.suggestions?.map((suggestion) => ({
                    mainText: suggestion.placePrediction.structuredFormat.mainText.text,
                    secondaryText: suggestion.placePrediction.structuredFormat.secondaryText.text,
                    placeId: suggestion.placePrediction.placeId,
                    sessionToken
                })) ?? []
            );
        },
        [sessionToken]
    );

    const getPlace = useCallback(async (autocompletedPlace: AutocompleteOption) => {
        const queryString = new URLSearchParams({
            ...placesApiCommonBody,
            fields: getPlaceFields.join(','),
            sessionToken: autocompletedPlace.sessionToken
        }).toString();

        const request = await fetch(`${placesApiEndpoint}/${autocompletedPlace.placeId}?${queryString}`);

        const response: PlaceResponse = await request.json();
        if (!request.ok) throw new Error(response.error);

        const place: Place = {
            address: {
                address: response.formattedAddress,
                country: findAddressComponentByType(response.addressComponents, 'country')?.shortText?.toUpperCase() ?? null,
                city: findAddressComponentByType(response.addressComponents, 'locality')?.longText ?? null,
                state: findAddressComponentByType(response.addressComponents, 'administrative_area_level_1')?.longText ?? null,
                postal_code: findAddressComponentByType(response.addressComponents, 'postal_code')?.shortText ?? null,
                l1: [
                    findAddressComponentByType(response.addressComponents, 'street_number')?.shortText,
                    findAddressComponentByType(response.addressComponents, 'route')?.shortText
                ].join(' '),
                l2: null
            },
            industry: findIndustryKey(response.types),
            phone: response.internationalPhoneNumber,
            opening_hours: response.regularOpeningHours,
            google_place_id: autocompletedPlace.placeId,
            site: response.websiteUri
        };

        return place;
    }, []);

    const findAddressComponentByType = (components: PlaceAddressComponent[], type: string): PlaceAddressComponent | undefined => {
        return components.find(({ types }) => types.some((t) => t === type));
    };

    const findIndustryKey = (placeTypes: string[]) => {
        for (const [industryType, googleTypes] of Object.entries(IndustriesMapping) as [IndustryType, string[]][]) {
            if (googleTypes.some((googleType) => placeTypes.includes(googleType))) {
                return industryType;
            }
        }
        return '';
    };

    return { searchPlaces, getPlace };
};

export default useGooglePlacesApi;
