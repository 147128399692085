import { useMemo } from 'react';

type IntervalType = 'minute' | 'hour' | 'day' | 'week' | 'month';

type ParsedInputObjectType = {
    minute: number;
    hour: number;
    day?: number;
    week?: number;
    month?: number;
};

const intervals: Record<IntervalType, { pattern: RegExp; label: string[]; workHrs?: number }> = {
    minute: {
        pattern: new RegExp(/(\d+)(\s+)?min/gi),
        label: ['minute', 'minutes']
    },
    hour: {
        pattern: new RegExp(/(\d+)(\s+)?(h|hr|ho(ur)?)/gi),
        label: ['hour', 'hours']
    },
    day: {
        pattern: new RegExp(/(\d+)(\s+)?(d(ay)?)/gi),
        label: ['day', 'days'],
        workHrs: 8
    },
    week: {
        pattern: new RegExp(/(\d+)(\s+)?we(ek)?/gi),
        label: ['week', 'weeks'],
        workHrs: 56
    },
    month: {
        pattern: new RegExp(/(\d+)(\s+)?mo(nth)?/gi),
        label: ['month', 'months'],
        workHrs: 240
    }
};

const intervalKeys: Array<IntervalType> = ['minute', 'hour', 'day', 'week', 'month'];

export const parseStringToDate = (value: string, useHoursOnly = false): ParsedInputObjectType => {
    const result: ParsedInputObjectType = {
        minute: 0,
        hour: 0
    };

    intervalKeys.forEach((unit) => {
        const match = value.match(intervals[unit].pattern);
        const amount = match ? parseFloat(match[0]) : 0;

        if (useHoursOnly) {
            const inHours = intervals[unit].workHrs;
            const usedAmount = inHours ? amount * inHours : amount;
            if (unit === 'minute') {
                const hours = Math.floor(amount / 60);
                const minutes = amount % 60;

                result.hour += hours;
                result.minute = minutes;
            } else {
                result.hour += usedAmount;
            }
        } else {
            result[unit] ? (result[unit] += amount) : (result[unit] = amount);
        }
    });

    return result;
};

export const getSuggestion = (data: ParsedInputObjectType): string => {
    const result: string[] = [];

    intervalKeys.forEach((interval) => {
        const amount = data[interval];
        const [one, many] = intervals[interval].label;

        if (amount) {
            result.push(`${amount} ${amount === 1 ? one : many}`);
        }
    });

    return result.reverse().join(' ');
};

export const getValueInMinutes = (data: ParsedInputObjectType): number => {
    let result = 0;
    intervalKeys.forEach((key) => {
        const amount = data[key] ?? 0;
        if (key === 'minute') {
            result += amount;
        } else {
            const hrs = intervals[key].workHrs ?? 1;
            result += amount * hrs * 60;
        }
    });

    return result;
};

const useFreeDateInput = (value: string) => {
    const parsedObject = useMemo<ParsedInputObjectType>(() => parseStringToDate(value), [value]);
    const suggestion = useMemo<string>(() => getSuggestion(parsedObject), [parsedObject]);

    return { suggestion };
};

export default useFreeDateInput;
