import { Button, Chip, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import UpdatedStyleWrapper from '../../../../ui-component/updated-style-wrapper';
import EntityDrawerContainer from '../../../../ui-component/entity-drawer-layout/EntityDrawerContainer';
import EntityDrawerHeader from '../../../../ui-component/entity-drawer-layout/EntityDrawerHeader';
import useEntityDrawerSpacing from '../../../../ui-component/entity-drawer-layout/use-entity-drawer-spacing';
import useNotifications from '../../../../hooks/useNotifications';
import NotificationList from './NotificationList';

const NotificationsListEntityDialog = ({ onClose }: { onClose: () => void }) => {
    const { data: notifications, markReadAll, notificationsLength, unreadNotificationsLength } = useNotifications();

    const { spacingX } = useEntityDrawerSpacing();

    return (
        <UpdatedStyleWrapper>
            <EntityDrawerContainer>
                <Stack
                    direction="row-reverse"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                        minHeight: '48px',
                        px: spacingX,
                        py: 1,
                        color: 'grey.800'
                    }}
                >
                    <IconButton onClick={onClose} sx={{ color: 'inherit', mr: -1 }}>
                        <CloseIcon />
                    </IconButton>
                </Stack>

                <EntityDrawerHeader
                    title={
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Stack direction="row" spacing={1} alignItems="center" useFlexGap>
                                <>Notifications</>
                                <Chip
                                    size="small"
                                    label={unreadNotificationsLength}
                                    color={!!unreadNotificationsLength ? 'error' : 'warning'}
                                />
                            </Stack>
                            {!!unreadNotificationsLength && (
                                <Button variant="text" sx={{ color: 'primary.900' }} onClick={() => markReadAll(null)}>
                                    Mark All As Read
                                </Button>
                            )}
                        </Stack>
                    }
                />
                {notifications && !!notificationsLength ? (
                    <NotificationList notifications={notifications} />
                ) : (
                    <Typography variant="h3" align="center" color="textSecondary" sx={{ margin: 'auto 0' }}>
                        No notifications yet
                    </Typography>
                )}
            </EntityDrawerContainer>
        </UpdatedStyleWrapper>
    );
};

export default NotificationsListEntityDialog;
