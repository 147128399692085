import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '../utils/axios';
import { IEmployee, IEmployeePayload, IEmployeeResendInvitationPayload } from 'models/IEmployee';
import { IBaseParams, IPaginateResponse } from '../models/IPaginateResponse';
import { InviteActionFormData, InviteActionResponse } from '../views/employee/InviteForm';
import { AcceptInviteByPhoneRequest } from '../views/accept-invite/AcceptInviteByPhone';

interface IEmployeeQueryArg {
    search?: string | null;
}

const employeeAPI = createApi({
    reducerPath: 'employeeAPI',
    tagTypes: ['Employee'],
    baseQuery: axiosBaseQuery(),
    endpoints: (build) => ({
        fetchAllEmployees: build.query<IPaginateResponse<IEmployee[]>, IBaseParams>({
            query: ({ per_page = -1, page = 1, search = null, sort = null, order = null, trashed = null }) => ({
                url: `/employees`,
                method: 'GET',
                params: {
                    per_page,
                    page,
                    search,
                    sort,
                    order,
                    trashed
                }
            }),
            providesTags: () => ['Employee']
        }),
        fetchAllEmployeesWithSearch: build.query<IPaginateResponse<IEmployee[]>, IEmployeeQueryArg>({
            query: ({ search = null }) => ({
                url: `/employees`,
                method: 'GET',
                params: {
                    search
                }
            }),
            providesTags: () => ['Employee']
        }),
        getEmployee: build.query<IEmployee, string>({
            query: (id) => ({ url: `/employees/${id}`, method: 'GET' })
        }),
        createEmployee: build.mutation<IEmployee, IEmployeePayload>({
            query: (employee) => ({
                url: `/employees`,
                method: 'POST',
                data: employee
            }),
            invalidatesTags: ['Employee']
        }),
        updateEmployee: build.mutation<IEmployee, IEmployeePayload>({
            query: (employee) => ({
                url: `/employees/${employee.id}`,
                method: 'PUT',
                data: employee
            }),
            invalidatesTags: ['Employee']
        }),
        deleteEmployee: build.mutation<IEmployee, IEmployee>({
            query: (employee) => ({
                url: `/employees/${employee.id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Employee']
        }),
        getInviteAction: build.mutation<InviteActionResponse, Omit<InviteActionFormData, 'role'>>({
            query: (data: InviteActionFormData) => ({
                url: `/employees/get-action-for-invite`,
                method: 'POST',
                data
            })
        }),
        inviteExistingUser: build.mutation<IEmployee, InviteActionFormData>({
            query: (data: InviteActionFormData) => ({
                url: `/employees/invite-existing-user`,
                method: 'POST',
                data
            }),
            invalidatesTags: ['Employee']
        }),
        resendInviteToEmployee: build.mutation<IEmployee, IEmployeeResendInvitationPayload>({
            query: (data: IEmployeeResendInvitationPayload) => ({
                url: `/employees/${data.id}/invite/resend`,
                method: 'POST',
                data
            }),
            invalidatesTags: ['Employee']
        }),
        simpleCreateEmployee: build.mutation<IEmployee, string>({
            query: (email) => ({
                url: '/employees/simplified-invite',
                method: 'POST',
                data: { email }
            }),
            invalidatesTags: ['Employee']
        }),
        getNextAvailableDate: build.query<string, { employeeId: number; locationId: number; currentDate: string }>({
            query: ({ employeeId, locationId, currentDate }) => ({
                method: 'GET',
                url: `employees/${employeeId}/next-available-date?location_id=${locationId}&date=${currentDate}`
            }),
            keepUnusedDataFor: 5
        }),
        acceptInviteByPhone: build.mutation<unknown, AcceptInviteByPhoneRequest>({
            query: (data) => ({
                url: '/public/employee/accept-invite-by-phone',
                method: 'POST',
                data
            }),
            invalidatesTags: ['Employee']
        })
    })
});

export default employeeAPI;
