import { useMemo } from 'react';
import { IconCashBanknote } from '@tabler/icons-react';
import { styled } from '@material-ui/core/styles';

interface ServiceCostProps {
    service: { is_free: boolean; price: string | number | null } | null;
}

const StyledCost = styled('div')(({ theme }) => ({
    fontSize: '18px',
    color: theme.palette.widget.green,
    display: 'flex',
    alignItems: 'center',

    '& span': {
        whiteSpace: 'nowrap'
    },

    '& svg': {
        display: 'flex'
    }
}));

const ServiceCost = ({ service }: ServiceCostProps) => {
    const getServicePrice = useMemo(() => {
        if (service) {
            const priceNumber = service.price ? parseFloat(String(service.price)) : 0;
            return priceNumber > 0 ? priceNumber.toFixed(2) : null;
        }

        return null;
    }, [service]);

    return getServicePrice ? (
        <StyledCost>
            <span id="selected-service-payment-type-icon">
                <IconCashBanknote />
            </span>
            <span id="selected-service-payment-type-text">{getServicePrice}</span>
        </StyledCost>
    ) : null;
};

export default ServiceCost;
