import { FC, useMemo } from 'react';
import { FormikProps } from 'formik';
import { Grid, MenuItem, TextField } from '@mui/material';

import { RegistrationFormType } from '../types';
import AppointmentAddressBox from 'ui-component/AppointmentAddressBox';

import { IndustryOptions } from 'ui-component/google-places-autocomplete/types';
import GooglePlacesAutocomplete from 'ui-component/google-places-autocomplete/GooglePlacesAutocomplete';

type CompanyDataSubformPropsType = {
    formInstance: FormikProps<RegistrationFormType>;
};

const CompanyDataSubform: FC<CompanyDataSubformPropsType> = ({ formInstance }) => {
    const { values, errors, touched, handleBlur, setFieldValue, initialValues } = formInstance;

    const addressError = useMemo(() => {
        if (errors.address && typeof errors.address === 'object') {
            // Because Formik can't recognize correct error type
            // @ts-ignore
            return errors.address.address;
        }

        return errors.address;
    }, [errors.address]);

    const clearPlaceFields = () => {
        setFieldValue('address', initialValues.address);
        setFieldValue('industry', '');
        setFieldValue('phone', '');
        setFieldValue('opening_hours', '');
        setFieldValue('google_place_id', '');
        setFieldValue('site', '');
    };

    return (
        <>
            <Grid item xs={12}>
                <GooglePlacesAutocomplete
                    id="company_name"
                    name="company_name"
                    label="Business Name"
                    variant="outlined"
                    error={!!errors.company_name && !!touched.company_name}
                    helperText={touched.company_name ? errors.company_name : undefined}
                    inputValue={values.company_name}
                    onInputChange={(newValue) => {
                        // Reset some fields
                        clearPlaceFields();

                        setFieldValue('company_name', newValue);
                    }}
                    onPlaceRetrieved={(place) => {
                        // Reset some fields
                        clearPlaceFields();

                        setFieldValue('address', place.address);
                        setFieldValue('industry', place.industry);
                        setFieldValue('phone', place.phone);
                        setFieldValue('opening_hours', place.opening_hours);
                        setFieldValue('google_place_id', place.google_place_id);
                        setFieldValue('site', place.site);
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    id="industry"
                    name="industry"
                    label="Industry"
                    select
                    value={values.industry}
                    onBlur={handleBlur}
                    error={!!errors.industry && !!touched.industry}
                    helperText={touched.industry ? errors.industry : undefined}
                    onChange={(e) => setFieldValue('industry', e.target.value)}
                >
                    {Object.entries(IndustryOptions).map(([value, label]) => (
                        <MenuItem value={value} key={value}>
                            {label}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>

            <Grid item xs={12}>
                <AppointmentAddressBox
                    fullWidth
                    id="address"
                    name="address"
                    label="Address"
                    value={values.address}
                    onBlur={handleBlur}
                    onChange={(v) => setFieldValue('address', v)}
                    error={!!touched.address && !!errors.address}
                    helperText={touched.address ? addressError : undefined}
                />
            </Grid>
        </>
    );
};

export default CompanyDataSubform;
