import { BottomNavigation, BottomNavigationAction, Box, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate, useMatch } from 'react-router-dom';
import EventNoteOutlined from '@mui/icons-material/EventNoteOutlined';
import BottomSearchFilter from '../../ui-component/search-filter/BottomSearchFilter';
import SearchIcon from '@mui/icons-material/Search';
import { useCallback, useRef, useState } from 'react';
import Badge from '@mui/material/Badge';
import useNotifications from '../../hooks/useNotifications';
import useNotificationsListener from '../../hooks/useNotificationsListener';
import useNotificationItem from '../../hooks/useNotificationItem';
import UpdatedStyleWrapper from '../../ui-component/updated-style-wrapper';

import { useAppDispatch } from '../../hooks/redux';
import { DialogTypes, openDialog } from '../../store/slices/entityDialogsSlice';

const shakingSx = {
    '@keyframes horizontal-shaking': {
        '0%': { transform: 'translateX(0)' },
        '25%': { transform: 'translateX(3px)' },
        '50%': { transform: 'translateX(-3px)' },
        '75%': { transform: 'translateX(3px)' },
        '100%': { transform: 'translateX(0)' }
    },

    animation: 'horizontal-shaking 0.3s infinite'
};

const CBBottomNav = () => {
    const [popup, setPopup] = useState<'search' | 'notifications' | null>(null);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('mobile'));
    const navigate = useNavigate();
    const match = useMatch('/calendar');

    const dispatch = useAppDispatch();

    const { unreadNotificationsLength } = useNotifications();
    const { current } = useNotificationsListener();
    const { notificationData } = useNotificationItem(current);

    const anchorRef = useRef<HTMLButtonElement | null>(null);

    const openSearch = useCallback(() => {
        setPopup('search');
    }, []);

    const onOpenNotificationsList = useCallback(() => {
        dispatch(openDialog({ type: DialogTypes.Notifications, id: null }));
    }, [dispatch]);

    const closeAll = useCallback(() => {
        setPopup(null);
    }, []);

    if (!isMobile) {
        return null;
    }

    return (
        <UpdatedStyleWrapper>
            <Box sx={{ position: 'fixed', left: 0, right: 0, bottom: 0, zIndex: 999 }}>
                <BottomNavigation
                    sx={{
                        height: '56px',
                        background: '#fff',
                        '& .MuiBottomNavigationAction-root': {
                            p: 1,
                            '& .bottom-nav-icon': {
                                width: '100%',
                                maxWidth: '83px',
                                minWidth: '40px',
                                height: '40px',
                                color: 'newColors.darkBlue',
                                display: 'inline-flex',
                                borderRadius: '20px',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: 'transparent'
                            },

                            '&.active .bottom-nav-icon': {
                                color: '#fff',
                                backgroundColor: 'primary.900'
                            }
                        }
                    }}
                >
                    <BottomNavigationAction
                        icon={
                            <span className="bottom-nav-icon">
                                <SearchIcon />
                            </span>
                        }
                        onClick={openSearch}
                        className={popup === 'search' ? 'active' : undefined}
                    />
                    <BottomNavigationAction
                        onClick={() => navigate('/calendar')}
                        icon={
                            <span className="bottom-nav-icon">
                                <EventNoteOutlined />
                            </span>
                        }
                        className={match ? 'active' : undefined}
                    />
                    <BottomNavigationAction
                        ref={anchorRef}
                        icon={
                            <Box className="bottom-nav-icon" sx={current ? shakingSx : undefined}>
                                <Badge
                                    color="error"
                                    overlap="circular"
                                    variant="dot"
                                    invisible={!unreadNotificationsLength}
                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                >
                                    {notificationData.icon}
                                </Badge>
                            </Box>
                        }
                        onClick={onOpenNotificationsList}
                        className={popup === 'notifications' ? 'active' : undefined}
                    />
                </BottomNavigation>

                <BottomSearchFilter open={popup === 'search'} onClose={closeAll} />
            </Box>
        </UpdatedStyleWrapper>
    );
};

export default CBBottomNav;
