import { INotification, NotificationType } from '../models/INotification';
import { useCallback, useMemo } from 'react';
import { useAppDispatch } from './redux';
import { useNavigate } from 'react-router-dom';
import notificationAPI from '../services/NotificationService';

import MessageIcon from '@mui/icons-material/Message';
import EventNoteIcon from '@mui/icons-material/EventNote';
import PersonIcon from '@mui/icons-material/Person';
import CancelIcon from '@mui/icons-material/Cancel';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { DialogTypes, openDialog } from '../store/slices/entityDialogsSlice';

interface NotificationDataProps {
    title: string;
    icon: React.ReactElement;
}

const notificationsVariants: Record<NotificationType | 'default', Omit<NotificationDataProps, 'title'>> = {
    [NotificationType.appointmentCanceled]: {
        icon: <CancelIcon />
    },
    [NotificationType.appointmentDateUpdated]: {
        icon: <EventNoteIcon />
    },
    [NotificationType.appointmentCreated]: {
        icon: <EventNoteIcon />
    },
    [NotificationType.appointmentCompleted]: {
        icon: <EventNoteIcon />
    },
    [NotificationType.appointmentCheckedIn]: {
        icon: <PersonIcon />
    },
    [NotificationType.incomingMessage]: {
        icon: <MessageIcon />
    },
    default: {
        icon: <NotificationsOutlinedIcon />
    }
};

const useNotificationItem = (notification: INotification | null) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [readNotification] = notificationAPI.useMarkNotificationAsReadMutation();
    const [deleteNotification] = notificationAPI.useDeleteNotificationMutation();

    const notificationCustomer = notification?.data.customer;
    const customerName = useMemo(() => {
        if (notificationCustomer) {
            const { firstname, lastname } = notificationCustomer;
            return [firstname, lastname].filter((s) => !!s).join(' ');
        }

        return '';
    }, [notificationCustomer]);

    const notificationType = notification?.type ?? 'default';

    const notificationData = useMemo<NotificationDataProps>(() => {
        const data: NotificationDataProps = {
            ...notificationsVariants[notificationType],
            title: notification?.title ?? ''
        };
        return data;
    }, [notificationType, notification?.title]);

    const isMessage = useMemo(() => notificationType === NotificationType.incomingMessage, [notificationType]);

    const notificationAppointmentId = notification?.data.id;
    const closestUpcomingAppointmentId = notification?.data?.closest_upcoming_appointment?.id;
    const notificationId = notification?.id;
    const customerId = notification?.data?.customer?.id;

    const handleClickNotification = useCallback(async () => {
        notificationId && readNotification(notificationId);

        // Classic logic, open appointment from notification
        !isMessage && notificationAppointmentId && dispatch(openDialog({ id: notificationAppointmentId, type: DialogTypes.Appointment }));

        // Modern logic, open or appointment messaging or customer messaging
        if (isMessage) {
            navigate('?tab=messages');

            closestUpcomingAppointmentId && dispatch(openDialog({ id: closestUpcomingAppointmentId, type: DialogTypes.Appointment }));
            customerId && !closestUpcomingAppointmentId && dispatch(openDialog({ id: customerId, type: DialogTypes.Customer }));
        }
    }, [
        notificationAppointmentId,
        dispatch,
        isMessage,
        navigate,
        notificationId,
        readNotification,
        closestUpcomingAppointmentId,
        customerId
    ]);

    const handleDeleteNotification = useCallback(async () => {
        notificationId && deleteNotification(notificationId);
    }, [notificationId, deleteNotification]);

    return { customerName, notificationData, isMessage, handleClickNotification, handleDeleteNotification };
};

export default useNotificationItem;
