import { FC, useState } from 'react';
import { Autocomplete, Box, CircularProgress, Stack, TextField, TextFieldProps, Typography } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';

import { AutocompleteOption, Place } from './types';
import useGooglePlacesApi from './hooks/use-google-places-api';

type GooglePlacesAutocompleteProps = TextFieldProps & {
    inputValue: string;
    onInputChange: (value: string) => void;
    onPlaceRetrieved: (value: Place) => void;
};

const GooglePlacesAutocomplete: FC<GooglePlacesAutocompleteProps> = ({ inputValue, onInputChange, onPlaceRetrieved, ...rest }) => {
    const { searchPlaces, getPlace } = useGooglePlacesApi();
    const [foundPlaces, setFoundPlaces] = useState<AutocompleteOption[]>([]);
    const [placesLoading, setPlacesLoading] = useState(false);

    const debouncedSearchPlaces = useDebouncedCallback(async (textQuery: string) => {
        if (!textQuery) {
            setFoundPlaces([]);
            return;
        }

        setPlacesLoading(true);

        try {
            const places = await searchPlaces(textQuery);
            setFoundPlaces(places ?? []);
        } catch (error) {
            setFoundPlaces([]);
        } finally {
            setPlacesLoading(false);
        }
    }, 300);

    const handlePlaceSelected = async (autocompletedPlace: AutocompleteOption | string | null) => {
        if (!autocompletedPlace || typeof autocompletedPlace === 'string') return;

        onInputChange(autocompletedPlace.mainText);

        setPlacesLoading(true);

        try {
            onPlaceRetrieved(await getPlace(autocompletedPlace));
        } finally {
            setPlacesLoading(false);
        }
    };

    return (
        <Autocomplete
            fullWidth
            freeSolo
            disableClearable
            options={foundPlaces}
            loading={placesLoading}
            inputValue={inputValue}
            onChange={(_e, value) => handlePlaceSelected(value)}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.mainText)}
            filterOptions={(x) => x}
            renderInput={(params) => (
                <TextField
                    {...params}
                    {...rest}
                    onChange={(event) => {
                        onInputChange(event.target.value);
                        debouncedSearchPlaces(event.target.value);
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {placesLoading && <CircularProgress color="inherit" size={20} />}
                                {params.InputProps.endAdornment}
                            </>
                        )
                    }}
                />
            )}
            renderOption={(props, option: AutocompleteOption) => (
                <Box component="li" {...props} key={option.placeId}>
                    <Stack sx={{ wordWrap: 'break-word' }}>
                        <Typography variant="h5" noWrap>
                            {option.mainText}
                        </Typography>
                        <Typography variant="body2">{option.secondaryText}</Typography>
                    </Stack>
                </Box>
            )}
        />
    );
};

export default GooglePlacesAutocomplete;
