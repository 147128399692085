import { useMemo } from 'react';

import accountService from 'services/AccountService';
import companyService from 'services/CompanyService';

import { ProfileCardPayload, ProfileCardType } from '../../models/ProfileCard';

import useAuth from 'hooks/useAuth';

const useProfileCardService = (profileCardType: ProfileCardType) => {
    const { user } = useAuth();

    const isPersonalProfileCard = profileCardType === ProfileCardType.Personal;

    //------------------------------------------------------------------------------------------------------------------
    // Get Query
    const { data: personalProfileCardData, isLoading: personalProfileCardIsLoading } = accountService.useGetAccountCardDataQuery(null, {
        skip: !isPersonalProfileCard
    });
    const { data: businessProfileCardData, isLoading: businessProfileCardIsLoading } = companyService.useGetProfileCardDataQuery(
        { companyId: user?.currentCompany.id! },
        { skip: isPersonalProfileCard }
    );

    const profileCardData = isPersonalProfileCard ? personalProfileCardData : businessProfileCardData;
    const isProfileCardLoading = isPersonalProfileCard ? personalProfileCardIsLoading : businessProfileCardIsLoading;

    //------------------------------------------------------------------------------------------------------------------
    // Mutation
    const [updateAccountCardDataMutation] = accountService.useUpdateAccountCardDataMutation();
    const [updateProfileCardDataMutation] = companyService.useUpdateProfileCardDataMutation();

    const updateCardDataMutation = useMemo(
        () =>
            isPersonalProfileCard
                ? (data: ProfileCardPayload) => updateAccountCardDataMutation(data)
                : (data: ProfileCardPayload) =>
                      updateProfileCardDataMutation({
                          companyId: user?.currentCompany.id!,
                          data
                      }),
        [isPersonalProfileCard, updateAccountCardDataMutation, updateProfileCardDataMutation, user]
    );

    return { profileCardData, isProfileCardLoading, updateCardDataMutation };
};

export default useProfileCardService;
