import { FC, useMemo } from 'react';
import { IConversationThread } from '../../models/IAppointment';
import { Card, CardContent, Typography } from '@material-ui/core';
import { Stack } from '@mui/material';
import { format } from 'date-fns';
import useGetUsernameById from '../../hooks/use-get-username-by-id';
import ChatAttachment from './ChatAttachment';
import ChatText from './ChatText';

const ChatMessage: FC<{ thread: IConversationThread }> = ({ thread }) => {
    const username = useGetUsernameById(thread.created_by_employee_id);
    const timestamp = format(new Date(thread.created_at), 'h:mm a');

    const createdByType = thread.created_by?.type;
    const createdByName = thread.created_by?.name;
    const isMine = useMemo(() => createdByType !== 'customer', [createdByType]);

    const displayedUsername = useMemo(() => {
        if (thread.type === 'note') {
            return username ? `Note from ${username}` : 'Note';
        }

        if (createdByType === 'user') {
            return createdByName;
        }

        return username;
    }, [username, thread.type, createdByType, createdByName]);

    const getCardBgColor = useMemo(() => {
        if (thread.type === 'note') {
            return {
                bgcolor: 'warning.light',
                textColor: 'warning.dark',
                bodyColor: ''
            };
        }

        return {
            bgcolor: 'grey.50',
            textColor: createdByType === 'user' ? 'textSecondary' : 'primary.900',
            bodyColor: 'grey.800'
        };
    }, [thread.type, createdByType]);

    return (
        <Card sx={{ maxWidth: '85%', alignSelf: isMine ? 'flex-end' : undefined, bgcolor: getCardBgColor.bgcolor }}>
            <CardContent
                sx={{
                    p: '16px',
                    pb: '16px !important',
                    width: 'fit-content',
                    ml: 'auto'
                }}
            >
                <Stack spacing={1}>
                    {displayedUsername && (
                        <Typography variant="body2" sx={{ fontWeight: 600, minHeight: '21px' }} color={getCardBgColor.textColor}>
                            {displayedUsername}
                        </Typography>
                    )}
                    <Typography variant="body2" color={getCardBgColor.bodyColor}>
                        <ChatText text={thread.body} />
                        {thread.attachments?.map((attachment) => (
                            <ChatAttachment attachment={attachment} key={attachment.file_url} />
                        ))}
                    </Typography>
                    <Typography align="right" variant="subtitle2" sx={{ color: 'grey.300' }}>
                        {timestamp}
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    );
};

export default ChatMessage;
