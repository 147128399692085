import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// All available dialog types
export enum DialogTypes {
    Appointment = 'appointment',
    Customer = 'customer',
    BlockTime = 'block_time',
    Invite = 'invite',
    Ledger = 'ledger',
    RecurringEvent = 'recurring_event',
    Notifications = 'notifications'
}

// These dialogs will be rendered as normal drawer
export enum ShowAsNormalDrawerDialogTypes {
    Ledger = 'ledger',
    Notifications = 'notifications'
}

interface IEntityDialogsSliceState {
    entityType: DialogTypes | ShowAsNormalDrawerDialogTypes | null;
    entityId: number | null;
}

const initialState: IEntityDialogsSliceState = {
    entityType: null,
    entityId: null
};

export const entityDialogsSlice = createSlice({
    name: 'entityDialogs',
    initialState,
    reducers: {
        openDialog: (state, action: PayloadAction<{ type: DialogTypes | ShowAsNormalDrawerDialogTypes; id: number | null }>) => {
            state.entityType = action.payload.type;
            state.entityId = action.payload.id;
        },

        closeDialog: (state) => {
            state.entityType = null;
            state.entityId = null;
        }
    }
});

export const { openDialog, closeDialog } = entityDialogsSlice.actions;
