import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
    CompanyInsightsInput,
    CompanyInsightsItem,
    ICompany,
    ICompanyPayload,
    ICompanySettings,
    ICompanySettingsPayload,
    ITwilioNumber
} from '../models/ICompany';
import { IBaseParams, IPaginateResponse } from '../models/IPaginateResponse';
import { axiosBaseQuery } from '../utils/axios';
import { ICompanyApiKey, ICompanyApiKeyPayload, IWebhookCreatePayload, IWebhookEditPayload, IWebhookItem } from '../views/settings/types';
import { ProfileCardPayload, ProfileCardDataType } from '../models/ProfileCard';

const companyAPI = createApi({
    reducerPath: 'companyAPI',
    tagTypes: ['Company', 'Insights', 'Webhooks', 'ApiKeys', 'BusinessProfileCard'],
    baseQuery: axiosBaseQuery(),
    endpoints: (build) => ({
        fetchAllCompanies: build.query<IPaginateResponse<ICompany[]>, IBaseParams>({
            query: ({ per_page = null, page = 1, search = null, sort = null, order = null, trashed = null }) => ({
                url: `/companies`,
                method: 'GET',
                params: {
                    per_page,
                    page,
                    search,
                    sort,
                    order,
                    trashed
                }
            }),
            providesTags: () => ['Company']
        }),
        getCompany: build.query<ICompany, string>({
            query: (id) => ({ url: `companies/${id}`, method: 'GET' })
        }),
        createCompany: build.mutation<ICompany, ICompanyPayload>({
            query: (company) => ({
                url: `/companies`,
                method: 'POST',
                data: company
            }),
            invalidatesTags: ['Company']
        }),
        updateCompany: build.mutation<ICompany, ICompanyPayload & { id: number }>({
            query: (company) => ({
                url: `/companies/${company.id}`,
                method: 'PUT',
                data: company
            }),
            invalidatesTags: ['Company']
        }),
        updateCompanySettings: build.mutation<ICompanySettings, ICompanySettingsPayload>({
            query: (companySettings) => ({
                url: `/companies/${companySettings.companyId}/update-settings`,
                method: 'POST',
                data: companySettings
            }),
            invalidatesTags: ['Company']
        }),
        deleteCompany: build.mutation<ICompany, ICompany>({
            query: (company) => ({
                url: `/companies/${company.id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Company']
        }),
        getTwilioPhones: build.query<ITwilioNumber[], string>({
            query: (id) => ({ url: `companies/${id}/twilio-phones`, method: 'GET' })
        }),
        getCompanyInsights: build.query<CompanyInsightsItem[], CompanyInsightsInput>({
            query: ({ company_id, params }) => ({
                url: `companies/${company_id}/insights`,
                method: 'GET',
                params
            }),
            keepUnusedDataFor: 5,
            providesTags: () => ['Insights']
        }),

        getAvailableCompanyTriggers: build.query<Record<string, string>, number>({
            query: (companyId) => ({
                url: `companies/${companyId}/webhooks/triggers`,
                method: 'GET'
            })
        }),

        getCompanyWebhooks: build.query<
            IPaginateResponse<IWebhookItem[]>,
            {
                companyId: number;
                params?: IBaseParams;
            }
        >({
            query: ({ companyId, params }) => ({
                url: `companies/${companyId}/webhooks`,
                method: 'GET',
                params
            }),
            providesTags: ['Webhooks']
        }),

        createCompanyWebhook: build.mutation<Record<string, string>, IWebhookCreatePayload>({
            query: ({ companyId, data }) => ({
                url: `companies/${companyId}/webhooks`,
                method: 'POST',
                data
            }),
            invalidatesTags: ['Webhooks']
        }),

        updateCompanyWebhook: build.mutation<Record<string, string>, IWebhookEditPayload>({
            query: ({ companyId, data, hookId }) => ({
                url: `companies/${companyId}/webhooks/${hookId}`,
                method: 'PUT',
                data
            }),
            invalidatesTags: ['Webhooks']
        }),

        deleteCompanyWebhook: build.mutation<unknown, { companyId: number; hookId: number }>({
            query: ({ companyId, hookId }) => ({
                url: `companies/${companyId}/webhooks/${hookId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Webhooks']
        }),

        getCompanyApiKeys: build.query<
            IPaginateResponse<ICompanyApiKey[]>,
            {
                companyId: number;
                params?: IBaseParams;
            }
        >({
            query: ({ companyId, params }) => ({
                url: `companies/${companyId}/api-keys`,
                method: 'GET',
                params
            }),
            providesTags: ['ApiKeys']
        }),

        createCompanyApiKey: build.mutation<{ api_key: string }, { companyId: number; data: ICompanyApiKeyPayload }>({
            query: ({ companyId, data }) => ({
                url: `companies/${companyId}/api-keys`,
                method: 'POST',
                data
            }),
            invalidatesTags: ['ApiKeys']
        }),

        updateCompanyApiKey: build.mutation<
            unknown,
            {
                companyId: number;
                data: ICompanyApiKeyPayload;
                keyId: number;
            }
        >({
            query: ({ companyId, data, keyId }) => ({
                url: `companies/${companyId}/api-keys/${keyId}`,
                method: 'PUT',
                data
            }),
            invalidatesTags: ['ApiKeys']
        }),

        deleteCompanyApiKey: build.mutation<unknown, { companyId: number; keyId: number }>({
            query: ({ companyId, keyId }) => ({
                url: `companies/${companyId}/api-keys/${keyId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['ApiKeys']
        }),

        getProfileCardData: build.query<ProfileCardDataType, { companyId: number }>({
            query: ({ companyId }) => ({
                url: `companies/${companyId}/card`,
                method: 'GET'
            }),
            providesTags: ['BusinessProfileCard']
        }),

        updateProfileCardData: build.mutation<ProfileCardDataType, { companyId: number; data: ProfileCardPayload }>({
            query: ({ companyId, data }) => ({
                url: `companies/${companyId}/card`,
                method: 'POST',
                data
            }),
            invalidatesTags: ['BusinessProfileCard']
        })
    })
});

export default companyAPI;
