import useAvailableMenuItems from '../../menu-items/use-available-menu-items';
import { Navigate } from 'react-router-dom';

// TODO: Just prepare to move all other pages into Tools module
const ToolsDefaultScreen = () => {
    const { availableSettings } = useAvailableMenuItems();

    if (availableSettings.length) return <Navigate to="/tools/insights" replace />;

    return <Navigate to="/calendar" />;
};

export default ToolsDefaultScreen;
