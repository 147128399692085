import { useCallback, useMemo, useState } from 'react';
import useAuth from './useAuth';
import { SnackBarTypes } from '../store/snackbarReducer';
import useShowSnackbar from './useShowSnackbar';
import userAPI from '../services/AccountService';

const useTimeTracker = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const { user, checkAuthentication } = useAuth();
    const { showSnackbar } = useShowSnackbar();

    const [toggleTimeTracker] = userAPI.useToggleTimeTrackerMutation();

    const isTimeTrackerExtensionEnabled = useMemo(() => {
        return !!user?.currentCompany.settings?.time_tracking?.enabled;
    }, [user]);

    const isTimeTrackerStarted = useMemo(() => {
        return !!user?.employee.latest_timelog?.start_at && !user?.employee.latest_timelog?.end_at;
    }, [user]);

    const onToggleTimeTracker = useCallback(() => {
        setLoading(true);

        toggleTimeTracker(null)
            .unwrap()
            .then(async () => checkAuthentication())
            .catch((err) =>
                showSnackbar({
                    alertSeverity: SnackBarTypes.Error,
                    message: err.data || 'Error occurred, please contact administrator'
                })
            )
            .finally(() => setLoading(false));
    }, [checkAuthentication, toggleTimeTracker, showSnackbar]);

    const getInitialTime = useMemo((): Date | null => {
        if (!isTimeTrackerStarted) return null;

        return new Date(user?.employee.latest_timelog?.start_at!);
    }, [isTimeTrackerStarted, user]);

    return { isTimeTrackerExtensionEnabled, isTimeTrackerStarted, onToggleTimeTracker, getInitialTime, loading };
};

export default useTimeTracker;
