import { FC, useCallback, useMemo } from 'react';
import LoadingSpinner from '../../../ui-component/LoadingSpinner';
import { Alert, Box, Button, Stack } from '@mui/material';
import QRCode from 'react-qr-code';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { SnackBarTypes } from '../../../store/snackbarReducer';
import useShowSnackbar from '../../../hooks/useShowSnackbar';
import useProfileCardUrl from '../../../ui-component/profile-card/use-profile-card-url';
import { ProfileCardType } from '../../../models/ProfileCard';
import useProfileCardService from '../../../ui-component/profile-card/use-profile-card-service';

const ProfileCardLink: FC<{ profileCardType: ProfileCardType }> = ({ profileCardType }) => {
    const { showSnackbar } = useShowSnackbar();
    const { profileCardData, isProfileCardLoading } = useProfileCardService(profileCardType);

    const { webSiteSlugStartAdornment, webSiteSlugEndAdornment } = useProfileCardUrl();
    const isEnabled = useMemo(() => !!profileCardData?.enabled, [profileCardData]);
    const slug = profileCardData?.slug;

    const siteUrl = useMemo(() => {
        if (slug) {
            return `${webSiteSlugStartAdornment}${slug}${webSiteSlugEndAdornment}`;
        }
        return null;
    }, [slug, webSiteSlugEndAdornment, webSiteSlugStartAdornment]);

    const onCopy = useCallback(() => {
        if (siteUrl) {
            navigator.clipboard.writeText(siteUrl).then(() => {
                showSnackbar({ alertSeverity: SnackBarTypes.Success, message: 'URL copied' });
            });
        }
    }, [showSnackbar, siteUrl]);

    if (isProfileCardLoading) {
        return <LoadingSpinner />;
    }

    if (isEnabled && siteUrl) {
        return (
            <Stack spacing={2} sx={{ width: '290px', maxWidth: '100%', ml: 'auto', mr: 'auto' }}>
                <Box sx={{ borderRadius: '16px', p: 2, color: 'grey.200', border: '1px solid currentColor' }}>
                    <QRCode value={siteUrl} />
                </Box>
                <Button
                    variant="text"
                    className="forcedBg"
                    sx={{ textAlign: 'left', justifyContent: 'space-between' }}
                    endIcon={<FileCopyOutlinedIcon />}
                    onClick={onCopy}
                >
                    Copy Link
                </Button>
            </Stack>
        );
    }

    return <Alert severity="error">Profile Card is not enabled</Alert>;
};

export default ProfileCardLink;
