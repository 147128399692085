import moment, { Moment } from 'moment-timezone';
import { TimeZoneObject } from '../../ui-component/time-zone-select/types';
import { IAppointment, IAppointmentPayload } from '../../models/IAppointment';

export const default_time_zone = 'UTC';

export const getTimeZones = (showTimezoneOffset?: boolean) => {
    const timeZones: { name: string; offset: number }[] = [
        { name: 'UTC', offset: 0 },
        ...moment.tz.zonesForCountry('US', true), // USA
        ...moment.tz.zonesForCountry('PR', true) // Puerto Rico
    ];

    return timeZones.map((timezone, index) => {
        const tzAbbr = moment.tz(timezone.name).format('z');
        const label = tzAbbr === 'UTC' ? 'UTC' : `(${tzAbbr} / UTC${moment.tz(timezone.name).format('Z')}) ${timezone.name}`;
        return {
            id: index,
            label: showTimezoneOffset ? label : timezone.name,
            name: timezone.name,
            offset: -timezone.offset
        } as TimeZoneObject;
    });
};

export const isEventDateValid = (event: IAppointmentPayload | IAppointment, zone: string) =>
    moment.tz(event.start_at, zone) > moment().tz(zone);

export const setDayStart = (date: Moment) => date.set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
