import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';

// material-ui
import { Button, Stack } from '@material-ui/core';

// project imports
import AuthCardWrapper from './AuthCardWrapper';
import AuthWrapper from './AuthWrapper';
import { RootState } from '../../store/store';
import { SET_SUB_RENEW_DATA } from '../../store/account/actions';
import { useAppDispatch } from '../../hooks/redux';
import AnimateButton from 'ui-component/extended/AnimateButton';

const SubRequired = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { required_subscription, _ptxn } = useSelector((AppState: RootState) => AppState.account);

    const onPurchaseClick = useCallback(() => {
        dispatch({ type: SET_SUB_RENEW_DATA, payload: { required_subscription: null, _ptxn: null } });
        navigate(`/register?_ptxn=${_ptxn}`);
    }, [_ptxn, dispatch, navigate]);

    const onCancel = useCallback(() => {
        dispatch({ type: SET_SUB_RENEW_DATA, payload: { required_subscription: null, _ptxn: null } });
        navigate('/login');
    }, [dispatch, navigate]);

    if (!required_subscription || !_ptxn) {
        return <Navigate to="/login" />;
    }
    return (
        <AuthWrapper>
            <AuthCardWrapper cardSubheaderText="Subscription activation required">
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                    <AnimateButton>
                        <Button variant="outlined" onClick={onCancel}>
                            Cancel
                        </Button>
                    </AnimateButton>
                    <AnimateButton>
                        <Button variant="contained" color="secondary" onClick={onPurchaseClick}>
                            Purchase
                        </Button>
                    </AnimateButton>
                </Stack>
            </AuthCardWrapper>
        </AuthWrapper>
    );
};

export default SubRequired;
