import { AnyAction, combineReducers } from 'redux';
import snackbarReducer from './snackbarReducer';
import { configureStore } from '@reduxjs/toolkit';
import companyAPI from '../services/CompanyService';
import locationAPI from '../services/LocationService';
import customizationReducer from './customizationReducer';
import serviceAPI from '../services/ServiceService';
import accountReducer from './account/accountReducer';
import { LOGOUT } from './account/actions';
import employeeAPI from 'services/EmployeeService';
import customerAPI from 'services/CustomerService';
// import mailboxAPI from '../services/MailboxService';
// import conversationAPI from '../services/ConversationService';
import { confirmPopupSlice } from './confirmPopupSlice';
// import { selectConversationsSlice } from './slices/selectConversationsSlice';
import appointmentAPI from '../services/AppointmentService';
import appointmentWidgetAPI from '../services/WidgetService';
import { calendarFilterSlice } from './slices/calendarFilterSlice';
import { calendarSlice } from './slices/calendarSlice';
import notificationAPI from '../services/NotificationService';
import { userInviteSlice } from './slices/userInviteSlice';
import { outerAppointmentSlice } from './slices/outerAppointmentSlice';
import userAPI from '../services/AccountService';
import { submittingSlice } from './slices/SubmittingSlice';
import reportAPI from '../services/ReportService';
import initAPI from '../services/InitService';
import { widgetSlice } from './slices/widgetSlice';
import { mobileCreateButtonSlice } from './slices/mobileCreateButtonSlice';
import { layoutSlice } from './slices/layoutSlice';
import waiverAPI from 'services/WaiverService';
import couponApi from '../services/CouponService';
import callApi from '../services/CallService';
import { entityDialogsSlice } from './slices/entityDialogsSlice';
import authAPI from '../services/authService';
import productsAPI from '../services/ProductsService';
import siteBuilderAPI from '../services/SiteBuilderService';
import reviewManagementAPI from '../services/ReviewManagementService';
import tooltipApi from '../services/TooltipsService';

export const appReducer = combineReducers({
    customization: customizationReducer,
    snackbar: snackbarReducer,
    account: accountReducer,
    confirmPopup: confirmPopupSlice.reducer,
    // selectConversation: selectConversationsSlice.reducer,
    [companyAPI.reducerPath]: companyAPI.reducer,
    [locationAPI.reducerPath]: locationAPI.reducer,
    [serviceAPI.reducerPath]: serviceAPI.reducer,
    [employeeAPI.reducerPath]: employeeAPI.reducer,
    [customerAPI.reducerPath]: customerAPI.reducer,
    [waiverAPI.reducerPath]: waiverAPI.reducer,
    // [mailboxAPI.reducerPath]: mailboxAPI.reducer,
    // [conversationAPI.reducerPath]: conversationAPI.reducer,
    [appointmentAPI.reducerPath]: appointmentAPI.reducer,
    [appointmentWidgetAPI.reducerPath]: appointmentWidgetAPI.reducer,
    [notificationAPI.reducerPath]: notificationAPI.reducer,
    [userAPI.reducerPath]: userAPI.reducer,
    calendarFilter: calendarFilterSlice.reducer,
    calendar: calendarSlice.reducer,
    mobileCreateButton: mobileCreateButtonSlice.reducer,
    userInvitation: userInviteSlice.reducer,
    outerAppointment: outerAppointmentSlice.reducer,
    submitting: submittingSlice.reducer,
    widget: widgetSlice.reducer,
    [reportAPI.reducerPath]: reportAPI.reducer,
    [initAPI.reducerPath]: initAPI.reducer,
    layout: layoutSlice.reducer,
    [couponApi.reducerPath]: couponApi.reducer,
    [callApi.reducerPath]: callApi.reducer,
    entityDialogs: entityDialogsSlice.reducer,
    [authAPI.reducerPath]: authAPI.reducer,
    [productsAPI.reducerPath]: productsAPI.reducer,
    [siteBuilderAPI.reducerPath]: siteBuilderAPI.reducer,
    [reviewManagementAPI.reducerPath]: reviewManagementAPI.reducer,
    [tooltipApi.reducerPath]: tooltipApi.reducer
});

export const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: AnyAction) => {
    if (action.type === LOGOUT) {
        // Keep account state to prevent app freeze
        return appReducer(
            {
                ...state,
                // @ts-ignore
                snackbar: undefined,
                // @ts-ignore
                confirmPopup: undefined,
                // @ts-ignore
                // selectConversation: undefined,
                // @ts-ignore
                [companyAPI.reducerPath]: undefined,
                // @ts-ignore
                [locationAPI.reducerPath]: undefined,
                // @ts-ignore
                [serviceAPI.reducerPath]: undefined,
                // @ts-ignore
                [employeeAPI.reducerPath]: undefined,
                // @ts-ignore
                [customerAPI.reducerPath]: undefined,
                // @ts-ignore
                [waiverAPI.reducerPath]: undefined,
                // @ts-ignore
                // [mailboxAPI.reducerPath]: undefined,
                // @ts-ignore
                // [conversationAPI.reducerPath]: undefined,
                // @ts-ignore
                [appointmentAPI.reducerPath]: undefined,
                // @ts-ignore
                [appointmentWidgetAPI.reducerPath]: undefined,
                // @ts-ignore
                [notificationAPI.reducerPath]: undefined,
                // @ts-ignore
                [userAPI.reducerPath]: undefined,
                // @ts-ignore
                calendarFilter: undefined,
                // @ts-ignore
                calendar: undefined,
                // @ts-ignore
                mobileCreateButton: undefined,
                // @ts-ignore
                userInvitation: undefined,
                // @ts-ignore
                outerAppointment: undefined,
                // @ts-ignore
                submitting: undefined,
                // @ts-ignore
                [reportAPI.reducerPath]: undefined,
                // @ts-ignore
                [initAPI.reducerPath]: undefined,
                // @ts-ignore
                layout: undefined,
                // @ts-ignore
                [couponApi.reducerPath]: undefined,
                // @ts-ignore
                [callApi.reducerPath]: undefined,
                // @ts-ignore
                entityDialogs: undefined,
                // @ts-ignore
                [authAPI.reducerPath]: undefined,
                // @ts-ignore
                [productsAPI.reducerPath]: undefined,
                // @ts-ignore
                [siteBuilderAPI.reducerPath]: undefined,
                // @ts-ignore
                [reviewManagementAPI.reducerPath]: undefined,
                // @ts-ignore
                [tooltipApi.reducerPath]: undefined
            },
            action
        );
    }

    return appReducer(state, action);
};

export const setupStore = () =>
    configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false
            }).concat(
                companyAPI.middleware,
                locationAPI.middleware,
                serviceAPI.middleware,
                employeeAPI.middleware,
                customerAPI.middleware,
                waiverAPI.middleware,
                // mailboxAPI.middleware,
                // conversationAPI.middleware,
                appointmentAPI.middleware,
                appointmentWidgetAPI.middleware,
                notificationAPI.middleware,
                userAPI.middleware,
                reportAPI.middleware,
                couponApi.middleware,
                callApi.middleware,
                authAPI.middleware,
                productsAPI.middleware,
                siteBuilderAPI.middleware,
                reviewManagementAPI.middleware,
                tooltipApi.middleware
            )
    });

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
