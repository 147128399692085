import { ReactNode, useCallback } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import TableLoader from './TableLoader';
import TableEmpty from './TableEmpty';
import { SxProps } from '@mui/system';
import { SortableContainer, SortableContainerProps, SortStart, SortEvent } from 'react-sortable-hoc';

export type CbTableColumn = {
    id: string;
    label: string;
    sortable?: boolean;
};

// makes MUI TableBody sortable
const TableBodySortable = SortableContainer(({ children }: { children: ReactNode }) => <TableBody>{children}</TableBody>);

export type CBTablePropsType<T> = {
    columns: Array<CbTableColumn>;
    rows: Array<T>;
    renderRow: (row: T, index?: number) => ReactNode;
    isLoading?: boolean;
    sortingKey?: string;
    order?: 'asc' | 'desc';
    handleSorting?: (key: string) => void;
    containerSx?: SxProps;
    emptyLabel?: ReactNode;
    compact?: boolean;
    sortContainerProps?: SortableContainerProps;
};

const CBTable = <K,>({
    columns,
    rows,
    renderRow,
    isLoading,
    sortingKey,
    order,
    handleSorting,
    containerSx,
    emptyLabel,
    compact,
    sortContainerProps
}: CBTablePropsType<K>) => {
    const handleSortStart = useCallback(
        (startData: SortStart, event: SortEvent) => {
            const { node, helper } = startData;
            sortContainerProps?.onSortStart?.(startData, event);
            node.childNodes.forEach((child, index: number) => {
                (helper.childNodes[index] as HTMLElement).style.width = `${(child as HTMLElement).offsetWidth}px`;
            });
        },
        [sortContainerProps]
    );

    const TableBodyElem = useCallback(
        ({ children }: { children: ReactNode }) =>
            sortContainerProps ? (
                <TableBodySortable {...sortContainerProps} onSortStart={handleSortStart}>
                    {children}
                </TableBodySortable>
            ) : (
                <TableBody>{children}</TableBody>
            ),
        [handleSortStart, sortContainerProps]
    );

    return (
        <TableContainer className={`cb-table-container ${compact ? 'cb-table-container--compact' : ''}`} sx={containerSx}>
            <Table className="CbTable">
                <TableHead>
                    <TableRow>
                        {columns.map((column) => (
                            <TableCell key={`${column.id}_${column.label}`}>
                                {column.sortable ? (
                                    <TableSortLabel
                                        active={sortingKey === column.id}
                                        direction={sortingKey === column.id ? order : 'asc'}
                                        onClick={handleSorting ? () => handleSorting(column.id) : undefined}
                                    >
                                        {column.label}
                                    </TableSortLabel>
                                ) : (
                                    column.label
                                )}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBodyElem>
                    <>
                        {isLoading ? <TableLoader colSpan={columns.length} /> : null}
                        {!isLoading && !rows.length ? <TableEmpty colSpan={columns.length} text={emptyLabel} /> : null}
                        {!!rows.length && !isLoading ? rows.map(renderRow) : null}
                    </>
                </TableBodyElem>
            </Table>
        </TableContainer>
    );
};

export default CBTable;
