// material-ui
import StartTimeTrackerIcon from '@mui/icons-material/PlayCircleOutline';
import StopTimeTrackerIcon from '@mui/icons-material/StopCircleOutlined';

// project import
import CommonHeaderBtn from '../../../../ui-component/CommonHeaderBtn';
import useTimeTracker from '../../../../hooks/use-time-tracker';
import Timer from '../../../../ui-component/time-tracker/Timer';

const TimeTracker = () => {
    const { isTimeTrackerStarted, onToggleTimeTracker, getInitialTime, loading } = useTimeTracker();

    return (
        <>
            <CommonHeaderBtn onClick={onToggleTimeTracker} disabled={loading}>
                {!isTimeTrackerStarted && <StartTimeTrackerIcon />}
                {isTimeTrackerStarted && <StopTimeTrackerIcon />}
            </CommonHeaderBtn>
            {isTimeTrackerStarted && <Timer initialTime={getInitialTime} />}
        </>
    );
};

export default TimeTracker;
