/**
 * axios setup to use mock service
 */

import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import config from '../config';
import { BaseQueryFn } from '@reduxjs/toolkit/dist/query/react';
import { AppStore } from '../store/store';
import { LOGOUT } from '../store/account/actions';

export const axiosServices: AxiosInstance = axios.create();

export interface ApiRequestError {
    status?: number;
    response?: AxiosResponse;
    message: string;
    errors?: Record<string, string | string[]>; // Laravel validation errors
    /**
     * @deprecated Only for legacy support, use `message` instead
     * Do not use and replace if found in code!
     */
    data: string;
}

// interceptor for http
const interceptors = {
    setupInterceptors: (store: AppStore): void => {
        axiosServices.interceptors.request.use((axiosConfig: AxiosRequestConfig): AxiosRequestConfig => {
            axiosConfig.withCredentials = true;
            axiosConfig.baseURL = config.apiUrl;
            axiosConfig.headers.post = {
                'Content-Type': 'application/json;charset=utf-8',
                'Access-Control-Allow-Origin': '*'
            };

            return axiosConfig;
        });

        axiosServices.interceptors.response.use(
            (response: AxiosResponse): AxiosResponse => response,
            (error: AxiosError): Promise<ApiRequestError> => {
                // Force logout from Backoffice
                if (error.response?.status === 401 && store.getState().account.isLoggedIn) store.dispatch({ type: LOGOUT });

                const apiError: ApiRequestError = {
                    response: error.response,
                    status: error.response?.status,
                    message: error.response?.data?.message || error.message || 'Error occurred',
                    errors: error.response?.data?.errors,
                    data: error.response?.data?.message || error.message || 'Error occurred'
                };

                return Promise.reject(apiError);
            }
        );
    }
};

type AxiosBaseQueryArgs = {
    url: string;
    method: AxiosRequestConfig['method'];
    data?: AxiosRequestConfig['data'];
    params?: any;
    headers?: any;
};

export const axiosBaseQuery =
    (): BaseQueryFn<AxiosBaseQueryArgs, unknown, ApiRequestError> =>
    async ({ url, method, data, params, headers }: AxiosBaseQueryArgs): Promise<{ data: any } | { error: ApiRequestError }> => {
        try {
            const result = await axiosServices({ url, method, data, params, headers });
            return { data: result.data };
        } catch (error) {
            return { error: error as ApiRequestError };
        }
    };

export default interceptors;
