import { useCallback, useEffect, useState } from 'react';
import { IAppointment } from '../../../models/IAppointment';
import { axiosServices } from '../../../utils/axios';
import { CustomerAppointmentsType } from '../index';
import moment from 'moment/moment';

interface useCustomerAppointmentProps {
    customerId: number;
    type: CustomerAppointmentsType;
}

const useCustomerAppointments = ({ customerId, type }: useCustomerAppointmentProps) => {
    const [appointments, setAppointments] = useState<IAppointment[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [cursor, setCursor] = useState<string | null>(null);

    const getAppointments = useCallback(
        (cleanPrevious = false) => {
            if (isLoading) return;

            setIsLoading(true);

            const params: { [k: string]: string | number | null } = {
                paginator: 'cursorPaginate',
                cursor: cleanPrevious ? null : cursor,
                per_page: 10,
                sort: 'start_at',
                order: 'desc'
            };

            const dateFilterParam = type === 'upcoming' ? 'start_at_from' : 'start_at_to';
            params[dateFilterParam] = moment().toISOString();

            axiosServices
                .get(`/customers/${customerId}/appointments`, {
                    params
                })
                .then(({ data }) => {
                    const val = appointments?.length && !cleanPrevious ? [...appointments, ...data.data] : data.data;
                    setAppointments(val);
                    setCursor(data?.meta?.next_cursor);
                    setIsLoading(false);
                })
                .catch(() => {
                    setIsLoading(false);
                });
        },
        [isLoading, cursor, type, customerId, appointments]
    );

    useEffect(() => {
        getAppointments(true);
        // todo: Refactor this hook
        // Rule disabled because adding getAppointments forces to much re-renders
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    return { appointments, cursor, getAppointments, isLoading };
};

export default useCustomerAppointments;
