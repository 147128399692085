import { useEffect, useState } from 'react';
import moment from 'moment';

interface TimerProps {
    initialTime?: Date | null;
}

const Timer: React.FC<TimerProps> = ({ initialTime }) => {
    const [startTime] = useState<Date | null>(initialTime || null);
    const [elapsedTime, setElapsedTime] = useState<number>(0);
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (startTime) setIntervalId(setInterval(() => setElapsedTime(moment().diff(startTime, 'seconds')), 1000));

        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [startTime]);

    const formatTime = (seconds: number): string => {
        const duration = moment.duration(seconds, 'seconds');
        const hours = (duration.days() * 24 + duration.hours()).toString().padStart(2, '0');
        const minutes = duration.minutes().toString().padStart(2, '0');
        const secs = duration.seconds().toString().padStart(2, '0');

        return `${hours}:${minutes}:${secs}`;
    };

    return <div>{formatTime(elapsedTime)}</div>;
};

export default Timer;
