import { useNavigate, useSearchParams } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import config from 'config';
import { GuardProps } from 'types';
import { useEffect, useMemo } from 'react';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }: GuardProps) => {
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();

    // Allow to see /register page for authorized users too.
    // It's required for migrating existing users from Stripe to Paddle
    const [searchParams] = useSearchParams();
    const paddleTransactionId = useMemo(() => searchParams.get('_ptxn'), [searchParams]);

    useEffect(() => {
        if (isLoggedIn && !paddleTransactionId) {
            navigate(config.defaultPath, { replace: true });
        }
    }, [isLoggedIn, navigate, paddleTransactionId]);

    return children;
};

export default GuestGuard;
