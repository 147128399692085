import { useCallback, useEffect, useMemo, useState } from 'react';
import { ICustomer } from '../../../../models/ICustomer';
import { useDebouncedCallback } from 'use-debounce';
import { axiosServices } from '../../../../utils/axios';
import { IPaginateResponse } from '../../../../models/IPaginateResponse';
import { FormFieldProps } from '../../index';
import { Autocomplete, TextField, Box, Popper, Button, Stack } from '@mui/material';
import { displayPhone } from '../../../../utils/phone-helpers';
import CustomerForm from '../../../CustomerForm';
import useCustomerFormDefaults from '../../../../views/customer/hooks/use-customer-form-defaults';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import WestOutlinedIcon from '@mui/icons-material/WestOutlined';
import EntityDrawerContent from '../../../entity-drawer-layout/EntityDrawerContent';
import EntityDrawerActions from '../../../entity-drawer-layout/EntityDrawerActions';
import useDuplicateCustomerConfirm from '../../../../hooks/use-duplicate-customer-confirm';
import { stopSubmitting } from '../../../../store/slices/SubmittingSlice';
import useCreateCustomer from '../../../../hooks/use-create-customer';
import ExistingCustomerAlert from '../../../ExistingCustomerAlert';
import { useAppDispatch } from '../../../../hooks/redux';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import HighlightTextRender from '../../../highlight-text-render';
import SectionHeading from '../../../SectionHeading';
import PermanentEntityDrawer from '../../../PermanentEntityDrawer';

interface CustomerSelectProps extends Pick<FormFieldProps, 'handleBlur'> {
    error?: string | null;
    touched?: boolean;
    customer?: ICustomer | null;
    disabled?: boolean;
    onChange: (customer: ICustomer | null) => void;
}

const CustomerSelect = ({ handleBlur, error, customer, touched, disabled, onChange }: CustomerSelectProps) => {
    const [formOpened, setFormOpened] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchString, setSearchString] = useState<string>('');
    const [options, setOptions] = useState<ICustomer[]>([]);

    const dispatch = useAppDispatch();
    const { data: customerErrorData, handleCustomerError, existingCustomer, clearError } = useDuplicateCustomerConfirm();

    const handleCustomerCreationCb = useCallback(
        (data: ICustomer) => {
            onChange(data);
            setFormOpened(false);
            dispatch(stopSubmitting());
            clearError();
        },
        [onChange, dispatch, clearError]
    );

    const handleCreateCustomer = useCreateCustomer(handleCustomerCreationCb, handleCustomerError);

    const defaults = useCustomerFormDefaults(existingCustomer);

    const getOptions = useDebouncedCallback((searchTerm, callback) => {
        setLoading(true);
        axiosServices
            .get(`/customers/`, {
                params: {
                    search: searchTerm,
                    per_page: 10
                }
            })
            .then((data) => {
                callback(data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, 200);

    const getOptionLabel = useCallback((option: ICustomer) => {
        if (typeof option === 'string') {
            return option;
        }

        return `${option.firstname} ${option.lastname}`;
    }, []);

    const isPopperForcedToClose = useMemo(() => {
        const noSearchText = !searchString;
        const equalToOnlyOption = options.length === 1 && getOptionLabel(options[0]) === searchString;

        return noSearchText || equalToOnlyOption;
    }, [getOptionLabel, options, searchString]);

    const handleCustomerSelect = useCallback(
        (_e: React.SyntheticEvent, newVal: ICustomer | null) => {
            onChange(newVal);
        },
        [onChange]
    );

    const handleClose = useCallback(() => {
        setFormOpened(false);
        clearError();
    }, [clearError]);

    useEffect(() => {
        if (!searchString) return setOptions(customer ? [customer] : []);

        return getOptions(searchString, ({ data }: { data: IPaginateResponse<ICustomer[]> }) => {
            setOptions(data.data);
        });
    }, [customer, getOptions, searchString]);

    return (
        <>
            <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center" mb={1}>
                <SectionHeading>Customer</SectionHeading>
                <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => setFormOpened(true)}
                    disabled={disabled}
                    endIcon={<AddCircleOutlineOutlinedIcon />}
                >
                    Create New
                </Button>
            </Stack>

            <Box display="flex" gap={1}>
                <Autocomplete
                    filterOptions={(opts) => opts}
                    openOnFocus={false}
                    id="customer-select"
                    fullWidth
                    getOptionLabel={getOptionLabel}
                    options={options}
                    isOptionEqualToValue={(option, value) => value && option.id === value.id}
                    includeInputInList
                    value={customer || null}
                    onChange={handleCustomerSelect}
                    onBlur={handleBlur}
                    inputValue={searchString}
                    onInputChange={(_event, newInputValue) => setSearchString(newInputValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: <AccountCircleOutlinedIcon />
                            }}
                            id="customer"
                            name="customer"
                            label="Type name or search"
                            error={Boolean(touched && error)}
                            helperText={touched ? error : null}
                        />
                    )}
                    renderOption={(props, option) => {
                        const phone = option.is_pd_masked ? option.phone : displayPhone(option.phone ?? '');

                        return (
                            <li {...props}>
                                <Box sx={{ color: 'grey.800', lineHeight: 1.5 }}>
                                    <Box sx={{ fontSize: '1rem', lineHeight: 1.5 }}>
                                        <HighlightTextRender text={getOptionLabel(option)} query={searchString} />
                                    </Box>
                                    {phone && (
                                        <Box sx={{ opacity: '0.6', fontSize: '0.875rem' }}>
                                            {option.is_pd_masked ? phone : <HighlightTextRender text={phone} query={searchString} />}
                                        </Box>
                                    )}
                                    {option.email && (
                                        <Box
                                            sx={{
                                                opacity: '0.6',
                                                fontSize: '0.875rem'
                                            }}
                                        >
                                            {option.is_pd_masked ? (
                                                option.email
                                            ) : (
                                                <HighlightTextRender text={option.email} query={searchString} />
                                            )}
                                        </Box>
                                    )}
                                </Box>
                            </li>
                        );
                    }}
                    disabled={disabled}
                    noOptionsText={loading ? 'Loading...' : 'No options'}
                    PopperComponent={(props) => <Popper {...props} open={props.open && !isPopperForcedToClose} />}
                />
            </Box>

            <PermanentEntityDrawer
                open={formOpened}
                title={
                    <Stack direction="row" spacing={2} alignItems="center">
                        <WestOutlinedIcon color="primary" fontSize="small" />
                        <span>Create New Customer</span>
                    </Stack>
                }
                onClose={handleClose}
            >
                <EntityDrawerContent>
                    <Box>
                        {formOpened && (
                            <CustomerForm
                                customer={defaults}
                                onSubmit={(formData) =>
                                    handleCreateCustomer({
                                        ...formData,
                                        id: existingCustomer?.id
                                    })
                                }
                                formId="new-customer-form"
                                hideTitles
                                skipChangesCheck
                                isNew
                            />
                        )}

                        {customerErrorData ? (
                            <Box pt={2}>
                                <ExistingCustomerAlert email={customerErrorData?.email} phone={customerErrorData?.phone} />
                            </Box>
                        ) : null}
                    </Box>
                </EntityDrawerContent>
                <EntityDrawerActions>
                    <Button onClick={handleClose} variant="text" className="forcedBg">
                        Cancel
                    </Button>
                    <Button color="primary" variant="contained" type="submit" form="new-customer-form">
                        {existingCustomer ? 'Update & Add' : 'Create New'}
                    </Button>
                </EntityDrawerActions>
            </PermanentEntityDrawer>
        </>
    );
};

export default CustomerSelect;
