import { FC, useCallback } from 'react';
import { Autocomplete, TextField, TextFieldProps } from '@mui/material';

interface IDateAutocompleteProps extends Pick<TextFieldProps, 'label' | 'error' | 'helperText' | 'disabled'> {
    value: string;
    onChange: (v: string) => void;
    suggestion: string;
    onBlur?: () => void;
}

const DateAutocomplete: FC<IDateAutocompleteProps> = ({
    value,
    onChange,
    suggestion,
    label = 'Remind In',
    error,
    onBlur,
    helperText,
    disabled
}) => {
    const handleChange = useCallback(
        (_e: React.SyntheticEvent, v: string | null) => {
            onChange(v ?? '');
        },
        [onChange]
    );

    return (
        <Autocomplete
            value={value}
            onChange={handleChange}
            fullWidth
            options={!suggestion ? [] : [suggestion]}
            freeSolo
            renderInput={(params) => (
                <TextField
                    label={label}
                    {...params}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    error={error}
                    helperText={helperText}
                />
            )}
            filterOptions={() => (!suggestion ? [] : [suggestion])}
            isOptionEqualToValue={() => false}
            onBlur={onBlur}
            disabled={disabled}
        />
    );
};

export default DateAutocomplete;
