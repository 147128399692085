// material-ui
import { Theme, useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import { Stack, Typography, useMediaQuery } from '@material-ui/core';

//
import { Link } from 'react-router-dom';

// project import
import MainCard, { MainCardProps } from 'ui-component/cards/MainCard';
import Logo from '../../ui-component/Logo';

// style constant
const useStyles = makeStyles((theme: Theme) => ({
    card: {
        width: '100vw',
        maxWidth: '800px',
        '& > *': {
            flexGrow: 1,
            flexBasis: '50%'
        },
        [theme.breakpoints.down('sm')]: {
            margin: '8px'
        }
    },
    content: {
        padding: `${theme.spacing(5)} !important`,
        [theme.breakpoints.down('lg')]: {
            padding: `${theme.spacing(3)} !important`
        }
    }
}));

// ==============================|| AUTHENTICATION CARD WRAPPER ||============================== //

const AuthCardWrapper = ({ children, cardHeaderText, cardSubheaderText, ...other }: MainCardProps) => {
    const classes = useStyles();
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Stack justifyContent={matchDownSM ? 'flex-start' : 'center'} alignItems="center" sx={{ minHeight: '100vh' }}>
            <MainCard className={classes.card} contentClass={classes.content} {...other}>
                <Stack spacing={2} alignItems="stretch">
                    <Typography textAlign="center" component={Link} to="/" sx={{ mb: matchDownSM ? 0 : 3 }}>
                        <Logo />
                    </Typography>

                    {cardHeaderText && (
                        <Typography color={theme.palette.secondary.main} textAlign="center" variant={matchDownSM ? 'h3' : 'h2'}>
                            {cardHeaderText}
                        </Typography>
                    )}

                    {cardSubheaderText && (
                        <Typography fontSize="16px" textAlign="center">
                            {cardSubheaderText}
                        </Typography>
                    )}

                    {children}
                </Stack>
            </MainCard>
        </Stack>
    );
};

export default AuthCardWrapper;
