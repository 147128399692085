import { CountryIso2, defaultCountries, FlagEmoji, parseCountry, usePhoneInput } from 'react-international-phone';
import { TextField, TextFieldProps, Select, MenuItem, InputAdornment, Typography, IconButton, Link } from '@mui/material';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import SmsIcon from '@mui/icons-material/Sms';
import { useMemo } from 'react';
import { isValidPhone } from '../../utils/phone-helpers';

type CustomPhoneInputProps = Omit<TextFieldProps, 'onChange'> & {
    value?: string | null;
    onChange: (v: string) => void;
    hasActions?: boolean;
};

const CustomPhoneInput: React.FC<CustomPhoneInputProps> = ({ value, onChange, label, hasActions = false, variant, ...rest }) => {
    const { phone, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
        defaultCountry: 'us',
        value: value ?? '',
        countries: defaultCountries,
        forceDialCode: true,
        onChange: (data) => {
            const { country: changedCountry, phone: changedPhone } = data;
            const countryData = defaultCountries.find(([_, code]) => code === changedCountry);
            const code = countryData ? `+${parseCountry(countryData).dialCode}` : '';

            onChange(changedPhone.trim() === code ? '' : changedPhone);
        }
    });

    const actionsDisabled = useMemo(() => !value || !isValidPhone(value), [value]);

    return (
        <>
            <TextField
                label={label}
                value={phone}
                onChange={handlePhoneValueChange}
                type="tel"
                inputRef={inputRef}
                variant={variant}
                autoComplete="off"
                {...rest}
                InputProps={{
                    ...rest.InputProps,
                    startAdornment: (
                        <InputAdornment position="start" style={{ marginRight: '2px', marginLeft: '-8px' }}>
                            <Select
                                MenuProps={{
                                    style: {
                                        height: '300px',
                                        width: '360px',
                                        top: '10px',
                                        left: '-34px'
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left'
                                    }
                                }}
                                sx={{
                                    width: 'max-content',
                                    // Remove default outline (display only on focus)
                                    fieldset: {
                                        display: 'none'
                                    },
                                    '&.Mui-focused:has(div[aria-expanded="false"])': {
                                        fieldset: {
                                            display: 'block'
                                        }
                                    },
                                    // Update default spacing
                                    '.MuiSelect-select': {
                                        padding: '8px',
                                        paddingRight: '24px !important'
                                    },
                                    '& img.react-international-phone-flag-emoji': {
                                        width: '20px'
                                    },
                                    svg: {
                                        right: 0
                                    }
                                }}
                                value={country ?? ''}
                                onChange={(e) => setCountry(e.target.value as CountryIso2)}
                                renderValue={(v) => <FlagEmoji iso2={v} style={{ display: 'flex' }} />}
                                disabled={rest.disabled}
                            >
                                {defaultCountries.map((c) => {
                                    const parsedCountry = parseCountry(c);
                                    return (
                                        <MenuItem key={parsedCountry.iso2} value={parsedCountry.iso2}>
                                            <FlagEmoji iso2={parsedCountry.iso2} style={{ marginRight: '8px', width: '20px' }} />
                                            <Typography marginRight="8px">{parsedCountry.name}</Typography>
                                            <Typography color="gray">+{parsedCountry.dialCode}</Typography>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </InputAdornment>
                    ),

                    endAdornment: hasActions ? (
                        <>
                            <IconButton component={Link} size="small" href={`tel:${value}`} disabled={actionsDisabled}>
                                <PhoneInTalkIcon />
                            </IconButton>
                            <IconButton component={Link} size="small" href={`sms:${value}`} disabled={actionsDisabled}>
                                <SmsIcon />
                            </IconButton>
                        </>
                    ) : null
                }}
            />
        </>
    );
};

export default CustomPhoneInput;
