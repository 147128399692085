import useAuth from './useAuth';
import { useCallback, useEffect, useRef, useState } from 'react';
import { INotification } from '../models/INotification';
import notificationAPI from '../services/NotificationService';
import { useAppDispatch } from './redux';

const NOTIFICATION_VISIBILITY_TIMEOUT = 3000;

const useNotificationsListener = () => {
    const [current, setCurrent] = useState<INotification | null>(null);
    const [stack, setStack] = useState<INotification[]>([]);

    const timerRef = useRef<any>(null);
    const timer = timerRef.current;

    const dispatch = useAppDispatch();
    const auth = useAuth();
    const userId = auth?.user?.id;
    const currentCompanyId = auth?.user?.currentCompany?.id;

    const echoInstance = window.Echo;

    const dismissNotification = useCallback(() => {
        setCurrent(null);
        if (timer) clearTimeout(timer);
    }, [timer]);

    const hasNotification = useCallback((prev: INotification[], incoming: INotification) => prev.some((n) => n.id === incoming.id), []);

    const onReceiveNotification = useCallback(
        (notification: INotification) => {
            if (currentCompanyId === notification.data.company.id) {
                setStack((prevState) => (hasNotification(prevState, notification) ? prevState : [...prevState, notification]));
                dispatch(
                    notificationAPI.util?.updateQueryData('getAccountNotifications', null, (prev) =>
                        hasNotification(prev, notification) ? prev : [notification, ...prev]
                    )
                );
            }
        },
        [currentCompanyId, dispatch, hasNotification]
    );

    useEffect(() => {
        echoInstance?.private(`App.Models.User.${userId}`).listen('.broadcast.notification', onReceiveNotification);

        return () => {
            echoInstance?.private(`App.Models.User.${userId}`).stopListening('.broadcast.notification');
        };
    }, [userId, echoInstance, dispatch, onReceiveNotification]);

    useEffect(() => {
        if (!current && stack.length) {
            const [first, ...rest] = [...stack];
            setCurrent(first);
            setStack(rest);
            timerRef.current = setTimeout(() => setCurrent(null), NOTIFICATION_VISIBILITY_TIMEOUT);
        }
    }, [current, stack]);

    return { current, dismissNotification };
};

export default useNotificationsListener;
