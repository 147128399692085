// material-ui
import { List } from '@mui/material';

// assets
import { INotification } from 'models/INotification';
import NotificationItem from './NotificationItem';

import PerfectScrollbar from 'react-perfect-scrollbar';

// ==============================|| NOTIFICATION LIST ||============================== //

type NotificationListProps = {
    notifications: INotification[];
};

const NotificationList = ({ notifications }: NotificationListProps) => {
    return (
        <PerfectScrollbar>
            <List disablePadding component="nav">
                {notifications.map((notification) => (
                    <NotificationItem notification={notification} key={notification.id} />
                ))}
            </List>
        </PerfectScrollbar>
    );
};

export default NotificationList;
