import { FC, useCallback, useState } from 'react';
import { Alert, Box, Button } from '@mui/material';
import ApiKeyForm from './ApiKeyForm';
import { openConfirmPopup } from '../../../store/confirmPopupSlice';
import { startSubmitting, stopSubmitting } from '../../../store/slices/SubmittingSlice';
import { useAppDispatch } from '../../../hooks/redux';
import companyAPI from '../../../services/CompanyService';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const ApiKeyCreate: FC<{ companyId: number }> = ({ companyId }) => {
    const [open, setOpen] = useState(false);
    const [createApiKey, { isLoading: isCreating }] = companyAPI.useCreateCompanyApiKeyMutation();
    const dispatch = useAppDispatch();

    const onSuccess = useCallback(
        (apiKey: string) => {
            dispatch(
                openConfirmPopup({
                    title: 'API Key Created',
                    text: (
                        <Box>
                            <Box sx={{ mb: 3 }}>
                                Please save the API Key somewhere safe and accessible. For security reasons,{' '}
                                <Box component="strong" sx={{ fontWeight: 700 }}>
                                    you won&apos;t be able to view it again. If you lose this key, you&apos;ll need to generate a new one.
                                </Box>
                            </Box>
                            <Alert severity="info" sx={{ wordBreak: 'break-all' }}>
                                {apiKey}
                            </Alert>
                        </Box>
                    )
                })
            );
        },
        [dispatch]
    );

    const handleCreateKey = useCallback(
        (formData: { name: string }) => {
            dispatch(startSubmitting());
            createApiKey({ companyId, data: formData })
                .unwrap()
                .then((res) => {
                    setOpen(false);
                    onSuccess(res.api_key);
                })
                .catch(() => {})
                .finally(() => {
                    dispatch(stopSubmitting());
                });
        },
        [companyId, createApiKey, dispatch, onSuccess]
    );

    return (
        <>
            <Button
                size="small"
                color="primary"
                className="forcedBg"
                onClick={() => setOpen(true)}
                disableElevation
                endIcon={<AddCircleIcon />}
            >
                Add API Key
            </Button>
            <ApiKeyForm
                open={open}
                onClose={() => setOpen(false)}
                dialogTitle="New API Key"
                defaults={{ name: '' }}
                onSubmit={handleCreateKey}
                actionsDisabled={isCreating}
                dialogOkLabel="Create"
            />
        </>
    );
};

export default ApiKeyCreate;
