import EventNoteOutlined from '@mui/icons-material/EventNoteOutlined';
import LocationOnOutlined from '@mui/icons-material/LocationOnOutlined';
import WorkOutline from '@mui/icons-material/WorkOutline';
import AssignmentIndOutlined from '@mui/icons-material/AssignmentIndOutlined';
import PeopleAltOutlined from '@mui/icons-material/PeopleAltOutlined';
import CouponIcon from '../ui-component/icons/CouponsIcon';
import FeedOutlined from '@mui/icons-material/FeedOutlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import BuildIcon from '@mui/icons-material/Build';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined';
import RepManagementIcon from '../ui-component/icons/RepManagementIcon';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import LoyaltyOutlinedIcon from '@mui/icons-material/LoyaltyOutlined';
import WebOutlinedIcon from '@mui/icons-material/WebOutlined';

export const calendarItem = {
    id: 'calendar',
    role_subject: 'appointment',
    title: 'Calendar',
    type: 'item',
    url: '/calendar',
    icon: EventNoteOutlined,
    breadcrumbs: false
};

export const locationItem = {
    id: 'location',
    role_subject: 'location',
    title: 'Locations',
    type: 'item',
    url: '/location',
    icon: LocationOnOutlined,
    breadcrumbs: false
};

export const serviceItem = {
    id: 'service',
    role_subject: 'service',
    title: 'Services',
    type: 'item',
    url: '/service',
    icon: WorkOutline,
    breadcrumbs: false
};

export const serviceCategoriesItem = {
    id: 'service_categories',
    role_subject: 'service',
    role_action: 'update',
    title: 'Service Categories',
    type: 'item',
    url: '/service-categories',
    icon: CategoryOutlinedIcon,
    breadcrumbs: false
};

export const productItem = {
    id: 'products',
    role_subject: 'product',
    title: 'Products',
    type: 'item',
    url: '/products',
    icon: ShoppingBagOutlinedIcon,
    breadcrumbs: false
};

export const employeeItem = {
    id: 'employee',
    role_subject: 'employee',
    title: 'Staff',
    type: 'item',
    url: '/employee',
    icon: AssignmentIndOutlined,
    breadcrumbs: false
};

export const customerItem = {
    id: 'customer',
    role_subject: 'customer',
    title: 'Customers',
    type: 'item',
    url: '/customer',
    icon: PeopleAltOutlined,
    breadcrumbs: false
};

export const couponsItem = {
    id: 'coupons',
    role_subject: 'company',
    title: 'Coupons',
    type: 'item',
    url: '/coupons',
    icon: CouponIcon,
    breadcrumbs: false
};

export const organizationItem = {
    id: 'organization',
    role_subject: 'company',
    title: 'Organization',
    type: 'item',
    url: '/organization',
    icon: DomainOutlinedIcon,
    breadcrumbs: false
};
export const reportsItem = {
    id: 'reports',
    role_subject: 'company',
    title: 'Reports',
    type: 'item',
    url: '/reports',
    icon: FeedOutlined,
    breadcrumbs: false
};

export const preferencesItem = {
    id: 'preferences',
    role_subject: 'company',
    title: 'Preferences',
    type: 'item',
    url: '/preferences',
    icon: SettingsOutlinedIcon,
    breadcrumbs: false
};
export const widgetItem = {
    id: 'widget-settings',
    role_subject: 'company',
    title: 'Booking Widget',
    type: 'item',
    url: '/widget-settings',
    icon: FactCheckOutlinedIcon,
    breadcrumbs: false
};
export const formBuilderItem = {
    id: 'form-builder',
    role_subject: 'company',
    title: 'Form Builder',
    type: 'item',
    url: '/forms',
    icon: AssignmentOutlinedIcon,
    breadcrumbs: false
};

export const siteBuilderItem = {
    id: 'site-builder',
    role_subject: 'company',
    title: 'Site Builder',
    type: 'item',
    url: '/site-builder',
    icon: BuildIcon,
    breadcrumbs: false
};

export const reviewManagementItem = {
    id: 'review-management',
    title: 'Rep. Management',
    role_subject: 'location',
    role_action: 'update',
    type: 'item',
    url: '/review-management',
    breadcrumbs: false,
    icon: RepManagementIcon
};
export const loyaltyProgramItem = {
    id: 'loyalty_program',
    role_subject: 'company',
    title: 'Loyalty Program',
    type: 'item',
    url: '/loyalty-program',
    icon: LoyaltyOutlinedIcon,
    breadcrumbs: false
};

//----------------------------------------------------------------------------------------------------------------------
// Tools
export const insightsItem = {
    id: 'insights',
    role_subject: 'company',
    title: 'Insights',
    type: 'item',
    url: '/tools/insights',
    icon: DashboardIcon,
    breadcrumbs: false
};

export const businessProfileCardItem = {
    id: 'business-profile-card',
    role_subject: 'company',
    title: 'Business Profile Card',
    type: 'item',
    url: '/tools/business-profile-card',
    icon: WebOutlinedIcon,
    breadcrumbs: false
};
