import { useCallback } from 'react';
import { Moment } from 'moment-timezone';
import * as Yup from 'yup';

// mui
import { Autocomplete, Box, FormHelperText, Stack, TextField, Grid } from '@mui/material';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MomentAdapter from '@mui/lab/AdapterMoment';
import MobileDatePicker from '@mui/lab/MobileDatePicker';

// project imports
import { IEmployee, UserRole } from '../../../models/IEmployee';
import { gridSpacing } from '../../../store/constant';
import UserAvatar from '../../UserAvatar';
import AppTimePicker from '../../form/time-picker/AppTimePicker';
import useExtendedFormik from '../../../hooks/useExtendedFormik';

interface BlockTimeFormProps {
    userRole: UserRole | undefined;
    employees: IEmployee[];
    initialValues: BlockTimeFormType;
    onSubmit: (data: BlockTimeFormType) => void;
    isBlocked?: boolean;
}

export type BlockTimeFormType = {
    title?: string | null;
    employee: IEmployee | null;
    start: Moment;
    end: Moment;
};

const validationSchema = Yup.object()
    .shape({
        title: Yup.string().trim().nullable().notRequired(),
        employee: Yup.mixed().required().label('Employee'),
        start: Yup.mixed()
            .required()
            .test('start', 'Start Date must be before End Date', (start: Moment, ctx) => {
                const { end } = ctx.parent;
                return start.isBefore(end);
            })
            .label('Start Date'),
        end: Yup.mixed()
            .required()
            .test('end', 'End Date must be after Start Date', (end: Moment, ctx) => {
                const { start } = ctx.parent;
                return end.isAfter(start);
            })
            .label('End Date')
    })
    .required();

const BlockTimeForm = ({ userRole, employees, initialValues, onSubmit, isBlocked }: BlockTimeFormProps) => {
    const { values, handleChange, handleBlur, errors, touched, setFieldValue, setFieldTouched, setFieldError, handleSubmit } =
        useExtendedFormik<BlockTimeFormType>({
            enableReinitialize: true,
            validationSchema,
            initialValues,
            onSubmit,
            isBlocked
        });

    const onDateChange = useCallback(
        (v: Moment | null) => {
            if (v) {
                // keeps existing pickers time
                const newStartDate = values.start.clone().set({
                    year: v.get('year'),
                    date: v.get('date'),
                    month: v.get('month')
                });
                const newEndDate = values.end.clone().set({
                    year: v.get('year'),
                    date: v.get('date'),
                    month: v.get('month')
                });
                setFieldValue('start', newStartDate);
                setFieldValue('end', newEndDate);
            }
        },
        [values, setFieldValue]
    );

    const onStartTimeChange = useCallback(
        (v: Moment | null) => {
            if (v) {
                const newStart = values.start.clone().set({
                    hour: v.get('hour'),
                    minute: v.get('minutes')
                });
                setFieldValue('start', newStart);
                setFieldTouched('start');
            }
        },
        [setFieldTouched, setFieldValue, values.start]
    );

    const onEndTimeChange = useCallback(
        (v: Moment | null) => {
            if (v) {
                const newEnd = values.start
                    .clone()
                    .set({
                        hour: v.get('hour'),
                        minute: v.get('minutes')
                    })
                    .subtract(1, 'second');
                setFieldValue('end', newEnd);
                setFieldTouched('end');
            }
        },
        [setFieldTouched, setFieldValue, values.start]
    );

    return (
        <Stack component="form" id="block_time_form" spacing={gridSpacing} noValidate autoComplete="off" onSubmit={handleSubmit}>
            {/* ============ TITLE =============== */}
            <TextField
                id="title"
                name="title"
                label="Reason"
                fullWidth
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!errors.title && touched.title}
                helperText={touched.title ? errors.title : undefined}
            />
            {/* ============ EMPLOYEE SELECT =============== */}
            {userRole && userRole !== UserRole.Provider && (
                <Autocomplete
                    id="employee-id"
                    fullWidth
                    value={values.employee}
                    options={employees}
                    getOptionLabel={(option: IEmployee) => `${option.user.title} (${option.user.email})`}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    noOptionsText="No items match your search"
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Staff"
                            placeholder="Select Provider"
                            error={!!errors.employee && touched.employee}
                            helperText={touched.employee ? errors.employee : undefined}
                        />
                    )}
                    onChange={(_e, value) => {
                        setFieldValue('employee', value);
                        if (value) {
                            setFieldError('employee', undefined);
                        }
                        setFieldTouched('employee');
                    }}
                    renderOption={(props, option: IEmployee) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            <UserAvatar employee={option} sx={{ width: '30px', height: '30px', mr: 1 }} />
                            {option.user.title}
                        </Box>
                    )}
                />
            )}
            <Box>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={MomentAdapter}>
                            <MobileDatePicker<Moment>
                                label="Date"
                                views={['day']}
                                mask="MM/DD/YYYY"
                                showTodayButton
                                disableHighlightToday
                                showToolbar={false}
                                value={values.start}
                                onChange={onDateChange}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={6}>
                        <AppTimePicker value={values.start} onChange={onStartTimeChange} label="From" />
                    </Grid>
                    <Grid item xs={6}>
                        <AppTimePicker value={values.end.clone().add(1, 'second')} onChange={onEndTimeChange} label="To" />
                    </Grid>
                    <Grid item xs={12}>
                        {touched.start && errors.start && <FormHelperText error>{errors.start}</FormHelperText>}
                        {touched.end && errors.end && <FormHelperText error>{errors.end}</FormHelperText>}
                    </Grid>
                </Grid>
            </Box>
        </Stack>
    );
};

export default BlockTimeForm;
