import { useMemo } from 'react';
import useAuth from './useAuth';

const useImpersonate = () => {
    const { user } = useAuth();

    const isImpersonatedSession = useMemo(() => user?.is_impersonated ?? false, [user?.is_impersonated]);

    return { isImpersonatedSession };
};

export default useImpersonate;
