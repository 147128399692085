import { createContext, useCallback, useEffect, useMemo } from 'react';
import { ACCOUNT_INITIALIZE, LOGIN, LOGOUT } from 'store/account/actions';
import { axiosServices } from 'utils/axios';
import { IUser } from '../models/IUser';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { useLocation } from 'react-router-dom';
import config from '../config';
import Loader from '../ui-component/Loader';
import { useNavigate } from 'react-router';

export interface IRegisterPayloadCompany {
    lead_uuid: string;
    industry: string;
    name: string;
    subscription_type: string;
    time_zone: string;
    site?: string;
    opening_hours?: object;
    phone?: string;
    google_place_id?: string;
}

export interface RegisterPayload {
    email: string;
    firstname: string;
    lastname: string;
    password?: string;
    address: {
        address: string;
        city: string;
        state: string;
        postal_code: string;
        l1?: string;
        l2?: string;
        country: string | null;
    };
    company: IRegisterPayloadCompany;
    payment_processor_price_id: string;
}

interface SanctumContextType {
    login: (email: string, password: string) => Promise<void>;
    register: (values: RegisterPayload) => Promise<void>;
    logout: () => void;
    changeCompanyContext: (companyId: number) => Promise<void>;
    checkAuthentication: () => Promise<void>;
    user: IUser | null;
    isLoggedIn: boolean;
}

const SanctumContext = createContext<SanctumContextType>({
    login: async () => {},
    register: async () => {},
    logout: () => {},
    changeCompanyContext: async () => {},
    checkAuthentication: async () => {},
    user: null,
    isLoggedIn: false
});

export const SanctumProvider = ({ children }: { children: React.ReactElement }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const { user, isLoggedIn, isInitialized } = useSelector((AppState: RootState) => AppState.account);

    const csrf = () => axiosServices.get('csrf-cookie');

    const signOut = async () => {
        try {
            await axiosServices.post('/logout');
            return true;
        } catch (error) {
            return error;
        }
    };

    const register = useCallback(
        (values: RegisterPayload): Promise<any> =>
            new Promise((resolve, reject) => {
                csrf()
                    .then(() => {
                        axiosServices
                            .post('/validate-register', values)
                            .then(({ data }) => {
                                document.cookie = `cbLeadId=; max-age=${60 * 60 * 24}`;
                                resolve(data);
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }),
        []
    );

    const login = useCallback(
        (email: string, password: string): Promise<any> =>
            new Promise((resolve, reject) => {
                csrf()
                    .then(() => {
                        axiosServices
                            .post(
                                '/login',
                                {
                                    email,
                                    password,
                                    remember: null
                                },
                                {
                                    maxRedirects: 0
                                }
                            )
                            .then(() => {
                                axiosServices
                                    .get<IUser>('/account', {
                                        maxRedirects: 0
                                    })
                                    .then(({ data }) => {
                                        dispatch({
                                            type: LOGIN,
                                            payload: {
                                                isLoggedIn,
                                                isInitialized,
                                                user: data
                                            }
                                        });

                                        if (data.select_company_required && data.companies.length === 0) {
                                            navigate('/select-organization');
                                        } else {
                                            resolve(data);
                                        }
                                    });
                            })
                            .catch((error) => {
                                reject(error);
                                console.log('Error', error);
                            });
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }),
        [dispatch, navigate, isInitialized, isLoggedIn]
    );

    const logout = useCallback(() => {
        signOut().then(() => {
            dispatch({ type: LOGOUT });
        });
    }, [dispatch]);

    const changeCompanyContext = useCallback(
        (companyId: number): Promise<any> =>
            new Promise((resolve, reject) => {
                axiosServices
                    .post(
                        '/account/change-company',
                        {
                            company_id: companyId
                        },
                        {
                            maxRedirects: 0
                        }
                    )
                    .then(() => {
                        axiosServices
                            .get<IUser>('/account', {
                                maxRedirects: 0
                            })
                            .then(({ data }) => {
                                dispatch({
                                    type: LOGIN,
                                    payload: {
                                        isLoggedIn,
                                        isInitialized,
                                        user: data
                                    }
                                });
                                resolve(data);
                            });
                    })
                    .catch((error) => {
                        reject(error);
                    });
            }),
        [dispatch, isLoggedIn, isInitialized]
    );

    const checkAuthentication = useCallback(async (): Promise<void> => {
        try {
            const { data } = await axiosServices.get<IUser>('/account', {
                maxRedirects: 0
            });

            dispatch({
                type: ACCOUNT_INITIALIZE,
                payload: {
                    isInitialized,
                    isLoggedIn: true,
                    user: data
                }
            });

            if (data.select_company_required && data.companies.length === 0) navigate('/select-organization');
        } catch (error) {
            dispatch({
                type: ACCOUNT_INITIALIZE,
                payload: {
                    isInitialized,
                    isLoggedIn: false,
                    user: null
                }
            });
        }
    }, [dispatch, navigate, isInitialized]);

    const value = useMemo(
        () => ({
            login,
            logout,
            register,
            changeCompanyContext,
            checkAuthentication,
            user,
            isLoggedIn,
            isInitialized
        }),
        [changeCompanyContext, checkAuthentication, login, logout, register, isInitialized, isLoggedIn, user]
    );

    const excludedPatches = [config.publicPath, 'customer-auth/', 'customer-dashboard/'];
    useEffect(() => {
        if (excludedPatches.every((path) => !location.pathname.includes(path))) {
            checkAuthentication().then(() => {});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (excludedPatches.every((path) => !location.pathname.includes(path)) && !isInitialized) {
        return <Loader />;
    }

    return <SanctumContext.Provider value={value}>{children}</SanctumContext.Provider>;
};

export default SanctumContext;
