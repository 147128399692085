import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '../utils/axios';
import { InitData, SubscriptionTYPageResponse } from '../views/authentication/types';

const InitAPI = createApi({
    reducerPath: 'initAPI',
    tagTypes: ['Init'],
    baseQuery: axiosBaseQuery(),
    endpoints: (build) => ({
        init: build.query<InitData, { params?: Record<string, string | boolean> }>({
            query: ({ params }) => ({
                url: `/public/app-init`,
                method: 'GET',
                params
            })
        }),
        subscriptionTYPage: build.query<SubscriptionTYPageResponse, string>({
            query: (_ptxn) => ({
                url: `/subscription-typ?_ptxn=${_ptxn}`,
                method: 'GET'
            })
        })
    })
});

export default InitAPI;
