import { Autocomplete, Box, Popper, TextField } from '@mui/material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { displayPhone } from '../../utils/phone-helpers';
import HighlightTextRender from '../highlight-text-render';
import { FC, useCallback, useEffect, useState, FocusEvent, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { axiosServices } from '../../utils/axios';
import { ICustomer } from '../../models/ICustomer';
import { IPaginateResponse } from '../../models/IPaginateResponse';

type CustomerSearchProps = {
    customer: ICustomer | null;
    onChange: (v: ICustomer | null) => void;
    handleBlur?: (e: FocusEvent<any>) => void;
    error?: string | null;
    disabled?: boolean;
    clearAfterSelect?: boolean;
    excludedIds?: number[];
    onSelectNewOption?: (payload: { firstname: string; lastname: string }) => void;
};

const CustomerSearch: FC<CustomerSearchProps> = ({
    customer,
    onChange,
    handleBlur,
    error,
    disabled,
    clearAfterSelect,
    excludedIds = [],
    onSelectNewOption
}) => {
    const [loading, setLoading] = useState(false);
    const [searchString, setSearchString] = useState<string>('');
    const [options, setOptions] = useState<ICustomer[]>([]);

    const getOptions = useDebouncedCallback((searchTerm, callback) => {
        setLoading(true);
        axiosServices
            .get(`/customers/`, {
                params: {
                    search: searchTerm,
                    per_page: 10
                }
            })
            .then((data) => {
                callback(data);
            })
            .finally(() => {
                setLoading(false);
            });
    }, 200);

    const getOptionLabel = useCallback((option: ICustomer) => {
        if (typeof option === 'string') {
            return option;
        }

        // @ts-ignore
        if (option.id === 'new_customer') {
            const name = `${option.firstname} ${option.lastname ?? ''}`.trim();
            return `Add "${name}"`;
        }

        return `${option.firstname} ${option.lastname}`;
    }, []);

    const searchStringIsNameLike = useMemo(() => onSelectNewOption && searchString.trim().length > 2, [onSelectNewOption, searchString]);

    const isPopperForcedToClose = useMemo(() => {
        const noSearchText = !searchString;
        const equalToOnlyOption = options.length === 1 && getOptionLabel(options[0]) === searchString;

        return noSearchText || equalToOnlyOption;
    }, [getOptionLabel, options, searchString]);

    const handleCustomerSelect = useCallback(
        (_e: React.SyntheticEvent, newVal: ICustomer | null) => {
            // @ts-ignore
            if (newVal && newVal.id === 'new_customer' && onSelectNewOption) {
                onSelectNewOption({ firstname: newVal?.firstname, lastname: newVal?.lastname });
            } else {
                onChange(newVal);
            }
            if (clearAfterSelect) {
                setSearchString('');
            }
        },
        [onSelectNewOption, clearAfterSelect, onChange]
    );

    useEffect(() => {
        if (!searchString) return setOptions(customer ? [customer] : []);

        return getOptions(searchString, ({ data }: { data: IPaginateResponse<ICustomer[]> }) => {
            const filtered = data.data.filter(({ id }) => !excludedIds.includes(id));
            if (searchStringIsNameLike) {
                const [firstname, lastname] = searchString.trim().split(' ');
                const newOption = { id: 'new_customer', firstname, lastname: lastname ?? '' } as unknown as ICustomer;
                setOptions([...filtered, newOption]);
            } else {
                setOptions(filtered);
            }
        });
    }, [customer, getOptions, searchString, excludedIds, searchStringIsNameLike]);

    return (
        <Autocomplete
            filterOptions={(opts) => opts}
            openOnFocus={false}
            id="customer-select"
            fullWidth
            getOptionLabel={getOptionLabel}
            options={options}
            isOptionEqualToValue={(option, value) => value && option.id === value.id}
            includeInputInList
            value={customer || null}
            onChange={handleCustomerSelect}
            onBlur={handleBlur ? (e) => handleBlur(e) : undefined}
            inputValue={searchString}
            onInputChange={(_event, newInputValue) => setSearchString(newInputValue)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: <AccountCircleOutlinedIcon />
                    }}
                    id="customer"
                    name="customer"
                    label="Type name or search"
                    error={!!error}
                    helperText={error}
                />
            )}
            renderOption={(props, option) => {
                const phone = option.is_pd_masked ? option.phone : displayPhone(option.phone ?? '');

                return (
                    <li {...props}>
                        <Box sx={{ color: 'grey.800', lineHeight: 1.5 }}>
                            <Box sx={{ fontSize: '1rem', lineHeight: 1.5 }}>
                                <HighlightTextRender text={getOptionLabel(option)} query={searchString} />
                            </Box>
                            {phone && (
                                <Box sx={{ opacity: '0.6', fontSize: '0.875rem' }}>
                                    {option.is_pd_masked ? phone : <HighlightTextRender text={phone} query={searchString} />}
                                </Box>
                            )}
                            {option.email && (
                                <Box
                                    sx={{
                                        opacity: '0.6',
                                        fontSize: '0.875rem'
                                    }}
                                >
                                    {option.is_pd_masked ? option.email : <HighlightTextRender text={option.email} query={searchString} />}
                                </Box>
                            )}
                        </Box>
                    </li>
                );
            }}
            disabled={disabled}
            noOptionsText={loading ? 'Loading...' : 'No options'}
            PopperComponent={(props) => <Popper {...props} open={props.open && !isPopperForcedToClose} />}
        />
    );
};

export default CustomerSearch;
