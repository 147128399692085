import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '../utils/axios';
import { ILocation, ILocationPayload, LedgerTransaction } from '../models/ILocation';
import { IBaseParams, IPaginateResponse } from '../models/IPaginateResponse';

type CreateLedgerTransactionPayload = Pick<LedgerTransaction, 'amount' | 'description'>;

export type LedgerBalanceData = {
    balance: number;
};

export type LedgerBalancePayload = {
    old_balance: number;
    new_balance: number;
};

const locationAPI = createApi({
    reducerPath: 'locationAPI',
    tagTypes: ['Location', 'LocationLedger', 'LocationBalance'],
    baseQuery: axiosBaseQuery(),
    endpoints: (build) => ({
        fetchAllLocations: build.query<IPaginateResponse<ILocation[]>, IBaseParams>({
            query: ({ per_page = -1, page = 1, search = null, sort = null, order = null, trashed = null }) => ({
                url: `/locations`,
                method: 'GET',
                params: {
                    per_page,
                    page,
                    search,
                    sort,
                    order,
                    trashed
                }
            }),
            providesTags: () => ['Location']
        }),
        getLocation: build.query<ILocation, string>({
            query: (id) => ({ url: `/locations/${id}`, method: 'GET' })
        }),
        createLocation: build.mutation<ILocation, ILocationPayload>({
            query: (location) => ({
                url: `/locations`,
                method: 'POST',
                data: location
            }),
            invalidatesTags: ['Location']
        }),
        updateLocation: build.mutation<ILocation, ILocationPayload>({
            query: (location) => ({
                url: `/locations/${location.id}`,
                method: 'PUT',
                data: location
            }),
            invalidatesTags: ['Location']
        }),

        deleteLocation: build.mutation<ILocation, ILocation>({
            query: (location) => ({
                url: `/locations/${location.id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Location']
        }),

        getLocationBalance: build.query<LedgerBalanceData, number>({
            query: (locationId) => ({
                url: `/locations/${locationId}/ledger`,
                method: 'GET'
            }),
            keepUnusedDataFor: 5
        }),

        updateLocationBalance: build.mutation<LedgerBalanceData, { locationId: number; payload: LedgerBalancePayload }>({
            query: ({ locationId, payload }) => ({
                url: `/locations/${locationId}/ledger`,
                method: 'PUT',
                data: payload
            })
        }),

        fetchLocationLedger: build.query<
            IPaginateResponse<LedgerTransaction[]>,
            {
                locationId: number;
                params: IBaseParams;
            }
        >({
            query: ({ locationId, params: { per_page = 10, cursor } }) => ({
                url: `/locations/${locationId}/ledger/items`,
                method: 'GET',
                params: {
                    per_page,
                    cursor,
                    sort: 'created_at',
                    order: 'desc',
                    paginator: 'cursorPaginate'
                }
            }),
            keepUnusedDataFor: 5
        }),

        createLedgerTransaction: build.mutation<
            LedgerTransaction,
            {
                locationId: number;
                data: CreateLedgerTransactionPayload;
            }
        >({
            query: ({ locationId, data }) => ({
                url: `/locations/${locationId}/ledger/items`,
                method: 'POST',
                data
            })
        }),

        updateLedgerTransaction: build.mutation<
            LedgerTransaction,
            {
                locationId: number;
                itemId: number;
                data: CreateLedgerTransactionPayload;
            }
        >({
            query: ({ locationId, data, itemId }) => ({
                url: `/locations/${locationId}/ledger/items/${itemId}`,
                method: 'PUT',
                data
            })
        }),

        deleteLedgerTransaction: build.mutation<unknown, { locationId: number; itemId: number }>({
            query: ({ locationId, itemId }) => ({
                url: `/locations/${locationId}/ledger/items/${itemId}`,
                method: 'DELETE'
            })
        })
    })
});

export default locationAPI;
