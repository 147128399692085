import { useMemo } from 'react';
import { IconCalendarTime, IconId, IconLocation, IconNotes, IconUser } from '@tabler/icons-react';
import { Steps } from '../types';
import useDepositFunctions from '../../hooks/useDepositFunctions';

const allStepsKeys: Array<keyof Steps> = ['service', 'location', 'provider', 'date', 'survey', 'user', 'payment', 'final'];

const useSteps = (options: { staff_autoassign?: boolean; oneProvider?: boolean; needSurvey?: boolean }) => {
    const { servicePrice } = useDepositFunctions();

    const excludedKeys = useMemo<Array<'payment' | 'provider' | 'survey'>>(() => {
        const result: Array<'payment' | 'provider' | 'survey'> = [];
        if (!servicePrice) {
            result.push('payment');
        }

        if (options.staff_autoassign || options.oneProvider) {
            result.push('provider');
        }

        if (!options.needSurvey) {
            result.push('survey');
        }

        return result;
    }, [options.needSurvey, options.oneProvider, options.staff_autoassign, servicePrice]);

    const steps = useMemo<Steps>(() => {
        const result = {
            service: { index: 0, title: 'Service', icon: IconNotes },
            location: { index: 1, title: 'Location', icon: IconLocation },
            provider: { index: 2, title: 'Provider', icon: IconId },
            date: { index: 3, title: 'Date & Time', icon: IconCalendarTime },
            survey: { index: 4, title: 'Additional Questions', icon: IconUser },
            user: { index: 5, title: 'Your Information', icon: IconUser },
            payment: { index: 6, title: 'Payment', icon: IconUser },
            final: { index: 7, title: 'Final', icon: IconUser }
        };

        const usedKeys = [...allStepsKeys.filter((key) => !excludedKeys.some((ek) => ek === key))];

        allStepsKeys.forEach((key) => {
            if (excludedKeys.some((ek) => ek === key)) {
                delete result[key];
            }

            if (usedKeys.includes(key)) {
                result[key] = { ...result[key], index: usedKeys.indexOf(key) };
            }
        });

        return result;
    }, [excludedKeys]);

    const stepsLength = useMemo(() => Object.keys(steps).length, [steps]);

    return { steps, stepsLength };
};

export default useSteps;
